import { CdkDragDrop, CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 9999,
};

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
})
export class FileUploadComponent implements OnChanges, OnDestroy {
  @Input() fileFormArray: FormArray = null;
  @Input() canEdit = true;
  @Input() canOpen = true;
  @Input() multiple = true;
  @Input() showLabels = true;
  @Input() categories: string[] = [];
  @Input() requiredCategories: string[] = [];
  @Input() maxFileSize = null; // Megabytes

  private fileFormArraySub: Subscription;

  constructor(private snackBar: MatSnackBar) {}

  ngOnChanges(changes: SimpleChanges): void {
    const fileFormArrayChanges = changes.fileFormArray;
    if (
      fileFormArrayChanges != null &&
      fileFormArrayChanges.currentValue !== fileFormArrayChanges.previousValue
    ) {
      const fileFormArray = fileFormArrayChanges.currentValue;

      if (this.fileFormArraySub) {
        this.fileFormArraySub.unsubscribe();
      }

      fileFormArray.controls.forEach((formCtrl) => {
        this.loadFileDisplay(formCtrl);
      });

      this.fileFormArraySub = fileFormArray.valueChanges.subscribe((val) => {
        val.forEach((fileData, index) => {
          if (fileData.b64 == null || fileData.b64 === '') {
            const formCtrl = this.fileFormArray.controls[index];
            this.loadFileDisplay(formCtrl);
          }
        });
      });
    }
  }

  ngOnDestroy(): void {
    if (this.fileFormArraySub) {
      this.fileFormArraySub.unsubscribe();
    }
  }

  isRequired(category: string) {
    return this.requiredCategories.includes(category);
  }

  isImageDataUrl(dataUrl: string) {
    return (dataUrl != null && dataUrl.startsWith('data:image'))
  }

  buildFileForm(): FormGroup {
    return new FormGroup({
      file: new FormControl(null, Validators.required),
      b64: new FormControl({ value: '', disabled: true }),
      category: new FormControl(''),
    });
  }

  loadFileDisplay(formCtrl: AbstractControl): void {
    const reader = new FileReader();

    reader.onload = (event) => {
      const b64 = event.target?.result as string;
      formCtrl.get('b64').setValue(b64, { emitEvent: false });
    };

    reader.onerror = (err) => {
      console.info('Erro ao ler arquivo');
      console.error(err);
    };

    reader.readAsDataURL(formCtrl.get('file').value);
  }

  addFile(event: Event, category: string) {
    const inputElement = event.target as HTMLInputElement;
    const inputFileList = inputElement.files;
    const errors = {};
    let hasErrors = false;

    if (inputFileList.length == 0) return;

    for (let i = 0; i < inputFileList.length; i++) {
      const file = inputFileList.item(i);

      if (this.maxFileSize != null && file.size >= this.maxFileSize * 1048576) {
        errors[file.name] = [
          `Arquivo não pode ser maior que ${this.maxFileSize} MB.`,
        ];
        hasErrors = true;
      } else {
        const fileForm = this.buildFileForm();
        fileForm.patchValue({
          file: file,
          category: category,
        });
        this.fileFormArray.push(fileForm);
      }
    }

    if (hasErrors) {
      console.info('Erro ao adicionar arquivos');
      console.error(errors);

      this.snackBar.open('Erro ao adicionar arquivos', 'Ok', {
        duration: 5000,
      });
    }
  }

  removeFile(index: number) {
    if (index >= 0 && index < this.fileFormArray.length) {
      this.fileFormArray.removeAt(index);
      this.snackBar.open('Arquivo removido', 'Ok', { duration: 5000 });
    }
  }

  getFilesByCategory(category: string) {
    return this.fileFormArray.controls
      .filter((fileCtrl) => fileCtrl.get('category').value == category)
      .map((fileCtrl, index) => [fileCtrl, index]);
  }

  changeFileCategory(event: CdkDragDrop<string[]>) {
    const fileId = event.item.data.id;
    const newCategory = event.container.data;
    const oldCategory = event.previousContainer.data;

    if (newCategory == oldCategory) return;

    const fileCtrl = this.fileFormArray.controls.find(
      (fileCtrl) => fileCtrl.get('id').value == fileId
    );
    fileCtrl.get('category').setValue(newCategory);
  }

  openFile(file): void {
    if (!this.canOpen) {
      return;
    }

    // TODO
  }
}
