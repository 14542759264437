import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, expand, mergeMap, tap, toArray } from 'rxjs/operators';
import { EnumAlert } from 'src/app/shared/enums/alert.enum';
import { PaginatedResponse } from 'src/app/shared/interfaces/core.interface';
import {
  Company,
  CompanyFilter,
} from 'src/app/shared/interfaces/company.interface';
import { SettingsService } from '../settings/settings.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public apiUrl = null;

  constructor(
    private http: HttpClient,
    private settingService: SettingsService,
    private utilsService: UtilsService
  ) {
    this.apiUrl = this.settingService.apiUrl();
  }

  canEdit(company: Partial<Company>): boolean {
    if (company == null) {
      return true;
    }
    const status = company.status;
    const processableStatuses = [
      'DRAFT',
      'ON_HOLD',
      'REJECTED',
      'ACTIVE',
    ];
    return status == null || processableStatuses.includes(status);
  }

  canDelete(company: Partial<Company>): boolean {
    if (company == null) {
      return true;
    }
    const status = company.status;
    const processableStatuses = ['DRAFT', 'REJECTED'];
    return status == null || processableStatuses.includes(status);
  }

  canSubmitForReview(company: Partial<Company>): boolean {
    if (company == null) {
      return false;
    }
    const status = company.status;
    const processableStatuses = ['DRAFT', 'REJECTED', 'RETIRED'];
    return processableStatuses.includes(status);
  }

  canApprove(company: Partial<Company>): boolean {
    if (company == null) {
      return false;
    }
    const status = company.status;
    const processableStatuses = ['UNDER_REVIEW'];
    return processableStatuses.includes(status);
  }

  canReject(company: Partial<Company>): boolean {
    if (company == null) {
      return false;
    }
    const status = company.status;
    const processableStatuses = ['UNDER_REVIEW'];
    return processableStatuses.includes(status);
  }

  canPutOnHold(company: Partial<Company>): boolean {
    if (company == null) {
      return false;
    }
    const status = company.status;
    const processableStatuses = ['ACTIVE'];
    return processableStatuses.includes(status);
  }

  canRetire(company: Partial<Company>): boolean {
    if (company == null) {
      return false;
    }
    const status = company.status;
    const processableStatuses = ['ACTIVE'];
    return processableStatuses.includes(status);
  }

  canClose(company: Partial<Company>): boolean {
    if (company == null) {
      return false;
    }
    const status = company.status;
    const processableStatuses = ['ACTIVE', 'ON_HOLD', 'SUSPENDED', 'RETIRED'];
    return processableStatuses.includes(status);
  }

  canSuspend(company: Partial<Company>): boolean {
    if (company == null) {
      return false;
    }
    const status = company.status;
    const processableStatuses = ['ACTIVE', 'ON_HOLD', 'RETIRED'];
    return processableStatuses.includes(status);
  }

  canBan(company: Partial<Company>): boolean {
    if (company == null) {
      return false;
    }
    const status = company.status;
    const processableStatuses = [
      'ACTIVE',
      'ON_HOLD',
      'SUSPENDED',
      'RETIRED',
      'CLOSED',
    ];
    return processableStatuses.includes(status);
  }

  canReactivate(company: Partial<Company>): boolean {
    if (company == null) {
      return false;
    }
    const status = company.status;
    const processableStatuses = [
      'ON_HOLD',
      'RETIRED',
      'SUSPENDED',
      'BANNED',
      'CLOSED',
    ];
    return processableStatuses.includes(status);
  }

  getCompany(id: number): Observable<Company> {
    console.info(EnumAlert.RetrieveCompanyInit);
    return this.http
      .get<Company>(this.apiUrl.concat(`api/v1/enterprise/companies/${id}/`))
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.RetrieveCompanySuccess);
          console.debug(val);
        })
      );
  }

  createCompany(data: Company): Observable<Company> {
    console.info(EnumAlert.CreateCompanyInit);
    return this.http
      .post<Company>(this.apiUrl.concat('api/v1/enterprise/companies/'), data)
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.CreateCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.CreateCompanySuccess);
          console.debug(val);
        })
      );
  }

  patchCompany(id: number, data: Partial<Company>): Observable<Company> {
    console.info(EnumAlert.UpdateCompanyInit);
    return this.http
      .patch<Company>(
        this.apiUrl.concat(`api/v1/enterprise/companies/${id}/`),
        data
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.UpdateCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.UpdateCompanySuccess);
          console.debug(val);
        })
      );
  }

  updateCompany(id: number, data: Company): Observable<Company> {
    console.info(EnumAlert.UpdateCompanyInit);
    return this.http
      .put<Company>(
        this.apiUrl.concat(`api/v1/enterprise/companies/${id}/`),
        data
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.UpdateCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.UpdateCompanySuccess);
          console.debug(val);
        })
      );
  }

  deleteCompany(id: number): Observable<null> {
    console.info(EnumAlert.DeleteCompanyInit);
    return this.http
      .delete<null>(this.apiUrl.concat(`api/v1/enterprise/companies/${id}/`))
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.DeleteCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.DeleteCompanySuccess);
          console.debug(val);
        })
      );
  }

  getCompanies(filter = null): Observable<PaginatedResponse<Company>> {
    console.info(EnumAlert.RetrieveCompaniesInit);
    const params: HttpParams = this.utilsService.createHttpParams(filter);
    return this.http
      .get<PaginatedResponse<Company>>(
        this.apiUrl.concat('api/v1/enterprise/companies/'),
        {
          params,
        }
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveCompaniesError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          if (val.count) {
            console.info(EnumAlert.RetrieveCompaniesSuccess);
          } else {
            console.info(EnumAlert.RetrieveCompaniesEmpty);
          }
          console.debug(val);
        })
      );
  }

  submitCompanyForReview(id: number): Observable<Company> {
    console.info(EnumAlert.SubmitCompanyForReviewInit);
    return this.http
      .post<Company>(
        this.apiUrl.concat(
          `api/v1/enterprise/companies/${id}/submit_for_review/`
        ),
        null
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.SubmitCompanyForReviewError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.SubmitCompanyForReviewSuccess);
          console.debug(val);
        })
      );
  }

  approveCompany(id: number): Observable<Company> {
    console.info(EnumAlert.ApproveCompanyInit);
    return this.http
      .post<Company>(
        this.apiUrl.concat(`api/v1/enterprise/companies/${id}/approve/`),
        null
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.ApproveCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.ApproveCompanySuccess);
          console.debug(val);
        })
      );
  }

  rejectCompany(id: number): Observable<Company> {
    console.info(EnumAlert.RejectCompanyInit);
    return this.http
      .post<Company>(
        this.apiUrl.concat(`api/v1/enterprise/companies/${id}/reject/`),
        null
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RejectCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.RejectCompanySuccess);
          console.debug(val);
        })
      );
  }

  putCompanyOnHold(id: number): Observable<Company> {
    console.info(EnumAlert.PutCompanyOnHoldInit);
    return this.http
      .post<Company>(
        this.apiUrl.concat(`api/v1/enterprise/companies/${id}/put_on_hold/`),
        null
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.PutCompanyOnHoldError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.PutCompanyOnHoldSuccess);
          console.debug(val);
        })
      );
  }

  retireCompany(id: number): Observable<Company> {
    console.info(EnumAlert.RetireCompanyInit);
    return this.http
      .post<Company>(
        this.apiUrl.concat(`api/v1/enterprise/companies/${id}/retire/`),
        null
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetireCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.RetireCompanySuccess);
          console.debug(val);
        })
      );
  }

  closeCompany(id: number): Observable<Company> {
    console.info(EnumAlert.CloseCompanyInit);
    return this.http
      .post<Company>(
        this.apiUrl.concat(`api/v1/enterprise/companies/${id}/close/`),
        null
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.CloseCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.CloseCompanySuccess);
          console.debug(val);
        })
      );
  }

  suspendCompany(id: number): Observable<Company> {
    console.info(EnumAlert.SuspendCompanyInit);
    return this.http
      .post<Company>(
        this.apiUrl.concat(`api/v1/enterprise/companies/${id}/suspend/`),
        null
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.SuspendCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.SuspendCompanySuccess);
          console.debug(val);
        })
      );
  }

  banCompany(id: number): Observable<Company> {
    console.info(EnumAlert.BanCompanyInit);
    return this.http
      .post<Company>(
        this.apiUrl.concat(`api/v1/enterprise/companies/${id}/ban/`),
        null
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.BanCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.BanCompanySuccess);
          console.debug(val);
        })
      );
  }

  reactivateCompany(id: number): Observable<Company> {
    console.info(EnumAlert.ReactivateCompanyInit);
    return this.http
      .post<Company>(
        this.apiUrl.concat(`api/v1/enterprise/companies/${id}/reactivate/`),
        null
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.ReactivateCompanyError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.ReactivateCompanySuccess);
          console.debug(val);
        })
      );
  }

  getCompanyMembers(id: number): Observable<any> {
    console.info(EnumAlert.RetrieveMembershipsInit);
    return this.http
      .get(this.apiUrl.concat(`api/v1/enterprise/companies/${id}/members/`))
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveMembershipsError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          if (val.count) {
            console.info(EnumAlert.RetrieveMembershipsSuccess);
          } else {
            console.info(EnumAlert.RetrieveMembershipsEmpty);
          }
          console.debug(val);
        })
      );
  }

  getAllCompanies(filter?: CompanyFilter): Observable<Partial<Company>[]> {
    console.info(EnumAlert.RetrieveCompaniesInit);
    const params = this.utilsService.createHttpParams(filter);
    return this.http
      .get<PaginatedResponse<Partial<Company>>>(
        this.apiUrl.concat(`api/v1/enterprise/companies/`),
        {
          params: params,
        }
      )
      .pipe(
        expand(({ next }) => {
          return next
            ? this.http.get<PaginatedResponse<Partial<Company>>>(next)
            : EMPTY;
        }),
        mergeMap(({ results }) => results),
        catchError((err) => {
          console.info(EnumAlert.RetrieveCompaniesError);
          console.error(err);
          return throwError(() => err);
        }),
        toArray(),
        tap((val) => {
          if (val.length > 0) {
            console.info(EnumAlert.RetrieveCompaniesSuccess);
          } else {
            console.info(EnumAlert.RetrieveCompaniesEmpty);
          }
          console.debug(val);
        })
      );
  }

  getListSchema(): Observable<any> {
    console.info(EnumAlert.RetrieveCompanySchemaInit);
    return this.http
      .options(this.apiUrl.concat(`api/v1/enterprise/companies/`))
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveCompanySchemaError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.RetrieveCompanySchemaSuccess);
          console.debug(val);
        })
      );
  }

  getDetailSchema(id: number): Observable<any> {
    console.info(EnumAlert.RetrieveCompanySchemaInit);
    return this.http
      .options(this.apiUrl.concat(`api/v1/enterprise/companies/${id}/`))
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveCompanySchemaError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.RetrieveCompanySchemaSuccess);
          console.debug(val);
        })
      );
  }
}
