import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

@Component({
  selector: 'app-datetime-input',
  templateUrl: './datetime-input.component.html',
  styleUrls: ['./datetime-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatetimeInputComponent),
      multi: true,
    },
  ],
})
export class DatetimeInputComponent implements ControlValueAccessor {
  @Input() isLarge = false;
  @Input() showError = false;
  @Input() required = false;

  @Input()
  set disabled(isDisabled) {
    this.setDisabledState(isDisabled);
  }
  get disabled(): boolean {
    return this.form.disabled;
  }

  form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      datetime: new FormControl(null),
    });
  }

  get isEmpty(): boolean {
    const datetime = this.form.get('datetime').value;
    return datetime == null || datetime === '';
  }

  get value(): string {
    if (this.isEmpty) {
      return null;
    }
    return this.form.value;
  }

  set value(value: string) {
    if (value != null && value !== '') {
      this.form.patchValue({ datetime: value });
    } else {
      this.form.reset({ datetime: null });
    }

    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  writeValue(value): void {
    this.value = value;
  }

  validate(): ValidationErrors {
    if (this.isEmpty) {
      if (this.required) {
        return { invalid: true };
      }
    } else if (this.form.invalid) {
      return { invalid: true };
    }
    return null;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  onInputChange(): void {
    this.onChange(this.value);
  }

  onBlur(): void {
    this.onTouched();
  }
}
