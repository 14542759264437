import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(value: string | moment.Duration, noDurationText?: ''): string {
    const duration = moment.duration(value);
    if (duration.asSeconds() != 0) {
      return duration.humanize();
    }
    return noDurationText;
  }
}
