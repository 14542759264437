/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as moment from 'moment';
import jwtDecode from 'jwt-decode';

interface JWTPayload {
  jti: string;
  iat: number;
  exp: number;
  token_type: string;
  user_id: number;
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(public http: HttpClient, public router: Router) {}

  get token(): string {
    return localStorage.getItem('token');
  }

  get refresh(): string {
    return localStorage.getItem('refresh');
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('expires_at');
    this.router.navigate(['/login']);
  }

  setSsession(authResult) {
    const access = authResult.access;
    const payload = jwtDecode(access) as JWTPayload;
    const expiresAt = moment.unix(payload.exp);
    localStorage.setItem('token', authResult.access);
    if(authResult.refresh){
      localStorage.setItem('refresh', authResult.refresh);
    }
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }
}
