import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Coordinates } from 'src/app/shared/interfaces/address.interface';
import {
  validateLat,
  validateLng,
} from 'src/app/shared/validators/coordinates.validator';
import { requiredTogether } from 'src/app/shared/validators/form-group.validator';

@Component({
  selector: 'app-coordinates-input',
  templateUrl: './coordinates-input.component.html',
  styleUrls: ['./coordinates-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoordinatesInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CoordinatesInputComponent),
      multi: true,
    },
  ],
})
export class CoordinatesInputComponent implements ControlValueAccessor {
  @Input() isLarge = false;
  @Input() showError = false;
  @Input() required = false;

  @Input()
  set disabled(isDisabled) {
    this.setDisabledState(isDisabled);
  }
  get disabled(): boolean {
    return this.form.disabled;
  }

  form: FormGroup = null;

  constructor() {
    this.form = new FormGroup({
      type: new FormControl('Point'),
      coordinates: new FormArray(
        [new FormControl('', validateLng), new FormControl('', validateLat)],
        requiredTogether(Validators.required, ['0', '1'])
      ),
    });
  }

  get isEmpty(): boolean {
    const coordinates = this.form.get('coordinates') as FormArray;
    const lng = coordinates.controls[0].value;
    const lat = coordinates.controls[1].value;

    if ((lat != null && lat !== '') || (lng != null && lng !== '')) {
      return false;
    }
    return true;
  }

  get value(): Coordinates {
    if (this.isEmpty) {
      return null;
    }
    return this.form.value;
  }

  set value(value: Coordinates) {
    if (value != null) {
      this.form.patchValue(value);
    } else {
      this.form.reset({ type: 'Point', coordinates: ['', ''] });
    }

    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  writeValue(value): void {
    this.value = value;
  }

  validate(): ValidationErrors {
    if (this.isEmpty) {
      if (this.required) {
        return { invalid: true };
      }
    } else if (this.form.invalid) {
      return { invalid: true };
    }
    return null;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  onInputChange(): void {
    this.onChange(this.value);
  }

  onBlur(): void {
    this.onTouched();
  }
}
