import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './theme/admin/admin.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'pages/account/activate',
    loadChildren: () =>
      import('./pages/activate/activate.module').then(
        (m) => m.ActivatePageModule
      ),
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'companies',
        loadChildren: () =>
          import('./pages/companies/companies.module').then((m) => m.CompaniesModule),
      },
      {
        path: 'agendas',
        loadChildren: () =>
          import('./pages/agenda/agenda.module').then((m) => m.AgendasModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardPageModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'marketplaces',
        loadChildren: () =>
          import('./pages/marketplaces/marketplaces.module').then(
            (m) => m.MarketplacesModule
          ),
      },
      {
        path: 'stores',
        loadChildren: () =>
          import('./pages/stores/stores.module').then((m) => m.StoresModule),
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('./pages/accounts/accounts.module').then(
            (m) => m.AccountsModule
          ),
      },
      {
        path: 'transactions',
        loadChildren: () =>
          import('./pages/transactions/transactions.module').then(
            (m) => m.TransactionsModule
          ),
      },
      {
        path: 'transaction-entries',
        loadChildren: () =>
          import('./pages/transaction-entries/transaction-entries.module').then(
            (m) => m.TransactionEntriesModule
          ),
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('./pages/invoices/invoices.module').then(
            (m) => m.InvoicesModule
          ),
      },
      {
        path: 'fees',
        loadChildren: () =>
          import('./pages/fees/fees.module').then((m) => m.FeesModule),
      },
      {
        path: 'payments',
        loadChildren: () =>
          import('./pages/payments/payments.module').then(
            (m) => m.PaymentsModule
          ),
      },
      {
        path: 'transfers',
        loadChildren: () =>
          import('./pages/transfers/transfers.module').then(
            (m) => m.TransfersModule
          ),
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./pages/orders/orders.module').then(
            (m) => m.OrdersModule
          ),
      },
      {
        path: 'order-fulfillments',
        loadChildren: () =>
          import('./pages/order-fulfillments/order-fulfillments.module').then(
            (m) => m.OrderFulfillmentsModule
          ),
      },
      {
        path: 'order-cancellations',
        loadChildren: () =>
          import('./pages/order-cancellations/order-cancellations.module').then(
            (m) => m.OrderCancellationsModule
          ),
      },
      {
        path: 'order-returns',
        loadChildren: () =>
          import('./pages/order-returns/order-returns.module').then(
            (m) => m.OrderReturnsModule
          ),
      },
      {
        path: 'deliveries',
        loadChildren: () =>
          import('./pages/deliveries/deliveries.module').then(
            (m) => m.DeliveriesModule
          ),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./pages/products/products.module').then(
            (m) => m.ProductsModule
          ),
      },
      {
        path: 'inventory',
        loadChildren: () =>
          import('./pages/inventory/inventory.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'integrations',
        loadChildren: () =>
          import('./pages/integrations/integrations.module').then(
            (m) => m.IntegrationsModule
          ),
      },
      {
        path: 'payment-gateways',
        loadChildren: () =>
          import('./pages/payment-gateways/payment-gateways.module').then(
            (m) => m.PaymentGatewaysModule
          ),
      },
      {
        path: 'banks',
        loadChildren: () =>
          import('./pages/banks/banks.module').then((m) => m.BanksModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
