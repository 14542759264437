import { Injectable } from '@angular/core';

export interface INavigationItem {
  title: string;
  icon: string;
  url?: string;
  onlyStaff?: boolean;
  id: string;
  items?: INavigationItem[];
  type?: 'item' | 'accordion';
}

const navigationItems: Array<INavigationItem> = [
  {
    title: 'Dashboard',
    icon: 'stats-chart-outline',
    url: '/dashboard',
    id: 'dashboard',
  },
  {
    title: 'Agendas',
    icon: 'calendar-number-outline',
    url: '/agendas',
    id: 'agendas',
  },
  {
    title: 'Usuários',
    icon: 'people-outline',
    url: '/users',
    id: 'users',
  },
  {
    title: 'Empresas',
    icon: 'business-outline',
    url: '/companies',
    id: 'company',
  },
  {
    title: 'Lojas',
    icon: 'storefront-outline',
    url: '/stores',
    id: 'store',
  },
  {
    type: 'accordion',
    title: 'Vendas',
    icon: 'trending-up',
    id: 'sales',
    items: [
      {
        title: 'Pedidos',
        icon: 'pricetags-outline',
        url: '/orders',
        id: 'orders',
      },
      {
        title: 'Atendimentos',
        icon: 'checkbox-outline',
        url: '/order-fulfillments',
        id: 'order-fulfillments',
      },
      {
        title: 'Entregas',
        icon: 'car-outline',
        url: '/deliveries',
        id: 'deliveries',
      },
      {
        title: 'Cancelamentos',
        icon: 'close-circle-outline',
        url: '/order-cancellations',
        id: 'order-cancellations',
      },
      {
        title: 'Devoluções',
        icon: 'sync',
        url: '/order-returns',
        id: 'order-returns',
      },
    ],
  },
  {
    type: 'accordion',
    title: 'Inventário',
    icon: 'file-tray-stacked-outline',
    id: 'inventory',
    items: [
      {
        onlyStaff: true,
        title: 'Produtos',
        icon: 'bag-check-outline',
        url: '/products',
        id: 'products',
      },
      {
        title: 'Estoque',
        icon: 'bag-check-outline',
        url: '/inventory/products',
        id: 'inventory-products',
      },
      {
        title: 'Seriais',
        icon: 'bag-check-outline',
        url: '/inventory/items',
        id: 'inventory-items',
      },
      {
        title: 'Lotes',
        icon: 'bag-check-outline',
        url: '/inventory/batches',
        id: 'batches',
      },
      {
        title: 'Recebimentos',
        icon: 'bag-check-outline',
        url: '/inventory/receivements',
        id: 'inventory-receivements',
      },
      {
        title: 'Movimentações',
        icon: 'bag-check-outline',
        url: '/inventory/movements',
        id: 'inventory-movements',
      },
    ],
  },
  {
    type: 'accordion',
    title: 'Financeiro',
    icon: 'cash-outline',
    id: 'finance',
    items: [
      {
        title: 'Contas',
        icon: 'wallet-outline',
        url: '/accounts',
        id: 'accounts',
      },
      {
        title: 'Faturas',
        icon: 'receipt-outline',
        url: '/invoices',
        id: 'invoices',
      },
      {
        title: 'Pagamentos',
        icon: 'cash-outline',
        url: '/payments',
        id: 'payments',
      },
      {
        title: 'Transferências',
        icon: 'arrow-forward',
        url: '/transfers',
        id: 'transfers',
      },
      {
        title: 'Transações',
        icon: 'swap-horizontal-outline',
        url: '/transaction-entries',
        id: 'transaction-entries',
      },
    ],
  },
  {
    onlyStaff: true,
    type: 'accordion',
    title: 'Sistema',
    icon: 'settings-outline',
    id: 'config',
    items: [
      {
        onlyStaff: true,
        title: 'Marketplaces',
        icon: 'storefront-outline',
        url: '/marketplaces',
        id: 'marketplaces',
      },
      {
        onlyStaff: true,
        title: 'Taxas',
        icon: 'receipt-outline',
        url: '/fees',
        id: 'fees',
      },
      {
        title: 'Gateways',
        icon: 'swap-horizontal-outline',
        url: '/payment-gateways',
        id: 'payment-gateways',
      },
      {
        title: 'Bancos',
        icon: 'business-outline',
        url: '/banks',
        id: 'banks',
      },
      {
        title: 'Transações',
        icon: 'swap-horizontal-outline',
        url: '/transactions',
        id: 'transactions',
      },
      {
        title: 'Integrações',
        icon: 'planet-outline',
        url: '/integrations',
        id: 'integrations',
      },
    ],
  },
];

@Injectable({ providedIn: 'root' })
export class NavigationItem {
  public get() {
    return navigationItems;
  }
}
