/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/naming-convention */

import { HttpParams } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  createHttpParams(params = null, filterNull = true): HttpParams {
    if (params == null) {
      return new HttpParams();
    }

    const filterNullFn = (obj) => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        if (filterNull && value == null) {
          return acc;
        }
        if (typeof value === 'object' && !Array.isArray(value)) {
          acc[key] = filterNullFn(value);
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});
    };

    if (filterNull) {
      params = filterNullFn(params);
    }
    return new HttpParams({ fromObject: params });
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  invalidField(formGroup: FormGroup, elementRef?: ElementRef) {
    console.log(formGroup);
    try {
      this.markFormGroupTouched(formGroup);
      if (elementRef) {
        this.scrollToFirstInvalidControl(elementRef, formGroup);
      }
    } catch (error) {
      console.log(error);
    }
  }

  scrollToFirstInvalidControl(elementRef, formGroup) {
    for (const key of Object.keys(formGroup.controls)) {
      if (formGroup.controls[key].invalid) {
        if (elementRef) {
          const invalidControl = elementRef.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]'
          );
          invalidControl?.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
          break;
        }
      }
    }
  }

  getChoices(path: string[], method: string, schema) {
    try {
      return path.reduce(
        (obj, property) => obj[property],
        schema.actions[method]
      ).choices;
    } catch {
      return [];
    }
  }

  getDisplayName(
    path: string[],
    value: string,
    method: string,
    schema
  ): string {
    if (value == null || value === '') {
      return '';
    }

    try {
      const choices = this.getChoices(path, method, schema);
      return choices.find((val) => val.value === value).display_name;
    } catch {
      return 'N/A';
    }
  }

  getMultiDisplayName(
    path: string[],
    value: string[],
    method: string,
    schema
  ): string {
    if (value == null || value.length === 0) {
      return '';
    }

    return value
      .map((val) => {
        try {
          const choices = this.getChoices(path, method, schema);
          return choices.find((val2) => val2.value === val).display_name;
        } catch {
          return 'N/A';
        }
      })
      .join(', ');
  }

  getError(err) {
    if (err.status === 0) {
      return 'Não foi possível estabelecer uma conexão com o servidor.';
    }

    const error = err.error;
    const detail = error.detail;
    if (detail) {
      return detail;
    }
    return JSON.stringify(error);
  }

  formatAddress(address) {
    const filterJoin = (array: string[], sep: string) =>
      array.filter((val) => val != null && val !== '').join(sep);

    const streetNumberCompl: string = filterJoin(
      [address.street, address.number, address.complement],
      ', '
    );
    const streetNumberComplDistr: string = filterJoin(
      [streetNumberCompl, address.district],
      '. '
    );
    const cityState: string = filterJoin([address.city, address.state], ' - ');
    const postcode: string = address.postcode || '';

    return filterJoin([streetNumberComplDistr, cityState, postcode], '. ');
  }

  coordinateTerms(value: (string | number)[]): string {
    /* Coordene uma lista de termos com vírgulas e conjunção. */
    if (value.length > 2) {
      const terms = [value.slice(0, -1).join(', '), String(value.slice(-1)[0])];
      return terms.join(' e ');
    } else {
      return value.join(' e ');
    }
  }

  openLink(url: string) {
    window.open(url, '_blank');
  }

  enumToChoices(enumObject: any) {
    return Object.values(enumObject).map((value: any) => {
      const parts = value.split(', ');
      return {
        value: parts[0],
        displayValue: parts[1],
      };
    });
  }

  displayEnum(value: string, enumObjects: any) {
    try {
      return enumObjects.find((e) => e.value === value).displayValue;
    } catch (error) {
      return value;
    }
  }
}
