import { TokenService } from './token.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router} from '@angular/router';
import { AlertService } from '../alert/alert.service';



@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{


  constructor(public router: Router, public tokenService: TokenService, private alertService: AlertService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let cloned = req;
    const token = localStorage.getItem('token');

    if(token) {
      cloned = cloned.clone({headers:req.headers.set('Authorization', 'Bearer '+ token)});
    }

    return next.handle(cloned).pipe(tap((event: HttpResponse<any>) => {},
    (err: any) => {
      if(err.status === 401){
        this.tokenService.logOut();
      }
    }));
  }
}


