import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export const atLeastOne =
  (validator: ValidatorFn, controls: string[] = null) =>
  (group: FormGroup): ValidationErrors => {
    if (!controls) {
      controls = Object.keys(group.controls);
    }
    const hasAtLeastOne = (group && group.controls && controls).some(
      (k) => !validator(group.controls[k])
    );
    return hasAtLeastOne ? null : { atLeastOne: true };
  };

export const requiredTogether =
  (validator: ValidatorFn, controls: string[] = null) =>
  (group: FormGroup): ValidationErrors => {
    if (!controls) {
      controls = Object.keys(group.controls);
    }

    const invalid = controls.some((k) => validator(group.controls[k]));
    if (invalid) {
      return { requiredTogether: true };
    }

    return null;
  };
