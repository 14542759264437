import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private alertController: AlertController) {}

  static successMessage(arg0: string) {
    throw new Error('Method not implemented.');
  }
  static errorMessage(arg0: string, arg1: any) {
    throw new Error('Method not implemented.');
  }

  async presentAlert(header: string, subHeader: string = '', message: string = '') {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }


  async presentWarningAlert(header: string, subHeader: string = '', message: string = ''): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header,
        subHeader,
        message,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
              resolve(false);
            },
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: () => {
              resolve(true);
            },
          },
        ],
      });
      await alert.present();
    });
  }



}
