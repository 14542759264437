import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IonicModule } from '@ionic/angular';
import { MaskitoModule } from '@maskito/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrMaskerModule } from 'br-mask';
import { AppointmentInputComponent } from './components/appointment-input/appointment-input.component';
import { CoordinatesInputComponent } from './components/coordinates-input/coordinates-input.component';
import { DatetimeInputComponent } from './components/datetime-input/datetime-input.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { CurrencyMaskDirective } from './directives/masks/currency-mask.directive';
import { CnpjPipe } from './pipes/cpf-cnpj.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { MoneyPipe } from './pipes/money.pipe';

@NgModule({
  declarations: [
    CoordinatesInputComponent,
    AppointmentInputComponent,
    DatetimeInputComponent,
    FileUploadComponent,
    DurationPipe,
    MoneyPipe,
    CnpjPipe,
    CurrencyMaskDirective,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
  ],
  exports: [
    CommonModule,
    IonicModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatInputModule,
    NgxDatatableModule,
    BrMaskerModule,
    MaskitoModule,
    CoordinatesInputComponent,
    AppointmentInputComponent,
    DatetimeInputComponent,
    FileUploadComponent,
    DurationPipe,
    CnpjPipe,
    MoneyPipe,
    CurrencyMaskDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
