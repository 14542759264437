import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionSheetController } from '@ionic/angular';
import { EnumAlert } from 'src/app/shared/enums/alert.enum';
import { TokenService } from 'src/app/shared/services/auth/token.service';
import { CompanyService } from 'src/app/shared/services/enterprise/company.service';
import { SettingsService } from 'src/app/shared/services/settings/settings.service';
import { INavigationItem, NavigationItem } from './navigation';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
  public navigation: Array<INavigationItem>;
  public labels = [];

  constructor(
    public navigationItem: NavigationItem,
    public settingsService: SettingsService,
    private snackBar: MatSnackBar,
    private companyService: CompanyService,
    private tokenService: TokenService,
    private actionSheetCtrl: ActionSheetController
  ) {
    this.navigation = this.navigationItem.get();
    if (!this.settingsService.loggedUser.is_staff) {
      this.navigation = this.navigation.filter((nav) => nav.onlyStaff !== true);
    }
  }

  async logOut() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Deseja realmente sair ?',
      buttons: [
        {
          text: 'Sim',
          role: 'true',
        },
        {
          text: 'Não',
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
    const result = await actionSheet.onDidDismiss();
    if (result.role === 'true') {
      this.tokenService.logOut();
    }
  }

  async getAllCompanies(): Promise<any> {
    try {
      const companies = await this.companyService.getAllCompanies().toPromise();
      return companies;
    } catch (err) {
      this.snackBar.open(EnumAlert.RetrieveCompaniesError, 'Ok', {
        duration: 5000,
      });
    }
  }

  async selectCompany() {
    const companies = await this.getAllCompanies();
    const buttons = [];

    companies.forEach((element) => {
      buttons.push({
        text: element.name,
        data: element,
      });
    });

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Qual empresa você deseja utilizar ?',
      buttons,
    });
    await actionSheet.present();
    const result = await actionSheet.onDidDismiss();
    this.settingsService.setCompany(result.data);
  }

  toggleMenu() {}
}
