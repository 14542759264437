/* eslint-disable @typescript-eslint/naming-convention */

export enum EnumAlert {
  // Generic CRUD
  CreateInit = 'Criando',
  CreateSuccess = 'Criado',
  CreateError = 'Erro ao criar',
  RetrieveInit = 'Obtendo dados',
  RetrieveSuccess = 'Dados obtidos',
  RetrieveError = 'Erro ao obter dados',
  RetrieveEmpty = 'Nenhum dado encontrado',
  UpdateInit = 'Atualizando',
  UpdateSuccess = 'Atualizado',
  UpdateError = 'Erro ao atualizar',
  DeleteInit = 'Excluindo',
  DeleteSuccess = 'Excluído',
  DeleteError = 'Erro ao excluir',
  RetrieveSchemaInit = 'Obtendo esquema',
  RetrieveSchemaSuccess = 'Esquema obtido',
  RetrieveSchemaError = 'Erro ao obter esquema',

  // Generic actions
  PreparationInit = 'Preparando dados',
  PreparationSuccess = 'Dados preparados',
  PreparationError = 'Erro ao preparar dados',
  OperationInit = 'Iniciando operação',
  OperationSuccess = 'Operação concluída',
  OperationError = 'Erro ao realizar operação',
  DownloadInit = 'Iniciando download',
  DownloadSuccess = 'Download concluído',
  DownloadError = 'Erro ao realizar download',

  // Generic errors
  NotFound = 'Não encontrado',

  // Users
  CreateUserInit = 'Criando usuário',
  CreateUserSuccess = 'Usuário criado',
  CreateUserError = 'Erro ao criar usuário',
  RetrieveUserInit = 'Obtendo usuário',
  RetrieveUserSuccess = 'Usuário obtido',
  RetrieveUserError = 'Erro ao obter usuário',
  UpdateUserInit = 'Atualizando usuário',
  UpdateUserSuccess = 'Usuário atualizado',
  UpdateUserError = 'Erro ao atualizar usuário',
  DeleteUserInit = 'Excluindo usuário',
  DeleteUserSuccess = 'Usuário excluído',
  DeleteUserError = 'Erro ao excluir usuário',
  RetrieveUsersInit = 'Obtendo usuários',
  RetrieveUsersSuccess = 'Usuários obtidas',
  RetrieveUsersError = 'Erro ao obter usuários',
  RetrieveUsersEmpty = 'Nenhum usuário encontrado',
  RetrieveUserSchemaInit = 'Obtendo esquema de usuários',
  RetrieveUserSchemaSuccess = 'Esquema de usuários obtido',
  RetrieveUserSchemaError = 'Erro ao obter esquema de usuários',

  // Companies
  CreateCompanyInit = 'Criando empresa',
  CreateCompanySuccess = 'Empresa criada',
  CreateCompanyError = 'Erro ao criar empresa',
  RetrieveCompanyInit = 'Obtendo empresa',
  RetrieveCompanySuccess = 'Empresa obtida',
  RetrieveCompanyError = 'Erro ao obter empresa',
  UpdateCompanyInit = 'Atualizando empresa',
  UpdateCompanySuccess = 'Empresa atualizada',
  UpdateCompanyError = 'Erro ao atualizar empresa',
  DeleteCompanyInit = 'Excluindo empresa',
  DeleteCompanySuccess = 'Empresa excluída',
  DeleteCompanyError = 'Erro ao excluir empresa',
  RetrieveCompaniesInit = 'Obtendo empresas',
  RetrieveCompaniesSuccess = 'Empresas obtidas',
  RetrieveCompaniesError = 'Erro ao obter empresas',
  RetrieveCompaniesEmpty = 'Nenhuma empresa encontrada',
  RetrieveCompanySchemaInit = 'Obtendo esquema de empresas',
  RetrieveCompanySchemaSuccess = 'Esquema de empresas obtido',
  RetrieveCompanySchemaError = 'Erro ao obter esquema de empresas',
  SubmitCompanyForReviewInit = 'Enviando empresa para revisão',
  SubmitCompanyForReviewSuccess = 'Empresa enviada para revisão',
  SubmitCompanyForReviewError = 'Erro ao enviar empresa para revisão',
  ApproveCompanyInit = 'Aprovando empresa',
  ApproveCompanySuccess = 'Empresa aprovada',
  ApproveCompanyError = 'Erro ao aprovar empresa',
  RejectCompanyInit = 'Rejeitando empresa',
  RejectCompanySuccess = 'Empresa rejeitada',
  RejectCompanyError = 'Erro ao rejeitar empresa',
  MarkCompanyAsPendingInit = 'Marcando empresa como pendente',
  MarkCompanyAsPendingSuccess = 'Empresa marcada como pendente',
  MarkCompanyAsPendingError = 'Erro ao marcar empresa como pendente',
  PutCompanyOnHoldInit = 'Colocando empresa em espera',
  PutCompanyOnHoldSuccess = 'Empresa colocada em espera',
  PutCompanyOnHoldError = 'Erro ao colocar empresa em espera',
  RetireCompanyInit = 'Descontinuando empresa',
  RetireCompanySuccess = 'Empresa descontinuada',
  RetireCompanyError = 'Erro ao descontinuar empresa',
  CloseCompanyInit = 'Fechando empresa',
  CloseCompanySuccess = 'Empresa fechada',
  CloseCompanyError = 'Erro ao fechar empresa',
  SuspendCompanyInit = 'Suspendendo empresa',
  SuspendCompanySuccess = 'Empresa suspensa',
  SuspendCompanyError = 'Erro ao suspender empresa',
  BanCompanyInit = 'Banindo empresa',
  BanCompanySuccess = 'Empresa banida',
  BanCompanyError = 'Erro ao banir empresa',
  ReactivateCompanyInit = 'Reativando empresa',
  ReactivateCompanySuccess = 'Empresa reativada',
  ReactivateCompanyError = 'Erro ao reativar empresa',

  // Company logs
  RetrieveCompanyLogsInit = 'Obtendo registros de empresa',
  RetrieveCompanyLogsSuccess = 'Registros de empresa obtido',
  RetrieveCompanyLogsError = 'Erro ao obter registros de empresa',
  RetrieveCompanyLogsEmpty = 'Nenhum registro de empresa encontrado',
  RetrieveCompanyLogSchemaInit = 'Obtendo esquema de registros de empresas',
  RetrieveCompanyLogSchemaSuccess = 'Esquema de registros de empresas obtido',
  RetrieveCompanyLogSchemaError = 'Erro ao obter esquema de registros de empresas',

  // Company roles
  CreateCompanyRoleInit = 'Criando papel organizacional',
  CreateCompanyRoleSuccess = 'Papel organizacional criado',
  CreateCompanyRoleError = 'Erro ao criar papel organizacional',
  RetrieveCompanyRoleInit = 'Obtendo papel organizacional',
  RetrieveCompanyRoleSuccess = 'Papel organizacional obtido',
  RetrieveCompanyRoleError = 'Erro ao obter papel organizacional',
  UpdateCompanyRoleInit = 'Atualizando papel organizacional',
  UpdateCompanyRoleSuccess = 'Papel organizacional atualizado',
  UpdateCompanyRoleError = 'Erro ao atualizar papel organizacional',
  DeleteCompanyRoleInit = 'Excluindo papel organizacional',
  DeleteCompanyRoleSuccess = 'Papel organizacional excluído',
  DeleteCompanyRoleError = 'Erro ao excluir papel organizacional',
  RetrieveCompanyRolesInit = 'Obtendo papéis organizacionais',
  RetrieveCompanyRolesSuccess = 'Papéis organizacionais obtidos',
  RetrieveCompanyRolesError = 'Erro ao obter papéis organizacionais',
  RetrieveCompanyRolesEmpty = 'Nenhum papel organizacional encontrado',
  RetrieveCompanyRoleSchemaInit = 'Obtendo esquema de papéis organizacionais',
  RetrieveCompanyRoleSchemaSuccess = 'Esquema de papéis organizacionais obtido',
  RetrieveCompanyRoleSchemaError = 'Erro ao obter esquema de papéis organizacionais',

  // Memberships
  CreateMembershipInit = 'Criando filiação',
  CreateMembershipSuccess = 'Filiação criada',
  CreateMembershipError = 'Erro ao criar filiação',
  RetrieveMembershipInit = 'Obtendo filiação',
  RetrieveMembershipSuccess = 'Filiação obtida',
  RetrieveMembershipError = 'Erro ao obter filiação',
  UpdateMembershipInit = 'Atualizando filiação',
  UpdateMembershipSuccess = 'Filiação atualizada',
  UpdateMembershipError = 'Erro ao atualizar filiação',
  DeleteMembershipInit = 'Excluindo filiação',
  DeleteMembershipSuccess = 'Filiação excluída',
  DeleteMembershipError = 'Erro ao excluir filiação',
  RetrieveMembershipsInit = 'Obtendo filiações',
  RetrieveMembershipsSuccess = 'Filiações obtidas',
  RetrieveMembershipsError = 'Erro ao obter filiações',
  RetrieveMembershipsEmpty = 'Nenhuma filiação encontrada',
  RetrieveMembershipSchemaInit = 'Obtendo esquema de filiações',
  RetrieveMembershipSchemaSuccess = 'Esquema de filiações obtido',
  RetrieveMembershipSchemaError = 'Erro ao obter esquema de filiações',

  // Company memberships
  SubmitMembershipInviteInit = 'Enviando convite de filiação',
  SubmitMembershipInviteSuccess = 'Convite de filiação enviado',
  SubmitMembershipInviteError = 'Erro ao enviar convite de filiação',
  CancelMembershipInviteInit = 'Cancelando convite de filiação',
  CancelMembershipInviteSuccess = 'Convite de filiação cancelado',
  CancelMembershipInviteError = 'Erro ao cancelar convite de filiação',
  AcceptMembershipRequestInit = 'Aceitando solicitação de filiação',
  AcceptMembershipRequestSuccess = 'Solicitação de filiação aceita',
  AcceptMembershipRequestError = 'Erro ao aceitar solicitação de filiação',
  DenyMembershipRequestInit = 'Negando solicitação de filiação',
  DenyMembershipRequestSuccess = 'Solicitação de filiação negada',
  DenyMembershipRequestError = 'Erro ao negar solicitação de filiação',
  RevokeMembershipInit = 'Revogando filiação',
  RevokeMembershipSuccess = 'Filiação revogada',
  RevokeMembershipError = 'Erro ao revogar filiação',

  // User memberships
  SubmitMembershipRequestInit = 'Enviando solicitação de filiação',
  SubmitMembershipRequestSuccess = 'Solicitação de filiação enviada',
  SubmitMembershipRequestError = 'Erro ao enviar solicitação de filiação',
  CancelMembershipRequestInit = 'Cancelando solicitação de filiação',
  CancelMembershipRequestSuccess = 'Solicitação de filiação cancelada',
  CancelMembershipRequestError = 'Erro ao cancelar solicitação de filiação',
  AcceptMembershipInviteInit = 'Aceitando convite de filiação',
  AcceptMembershipInviteSuccess = 'Convite de filiação aceito',
  AcceptMembershipInviteError = 'Erro ao aceitar convite de filiação',
  RefuseMembershipInviteInit = 'Recusando convite de filiação',
  RefuseMembershipInviteSuccess = 'Convite de filiação recusado',
  RefuseMembershipInviteError = 'Erro ao recusar convite de filiação',
  AbdicateMembershipInit = 'Abandonando filiação',
  AbdicateMembershipSuccess = 'Filiação abandonada',
  AbdicateMembershipError = 'Erro ao abandonar filiação',

  // Clients
  CreateClientInit = 'Criando cliente',
  CreateClientSuccess = 'Cliente criado',
  CreateClientError = 'Erro ao criar cliente',
  RetrieveClientInit = 'Obtendo cliente',
  RetrieveClientSuccess = 'Cliente obtido',
  RetrieveClientError = 'Erro ao obter cliente',
  UpdateClientInit = 'Atualizando cliente',
  UpdateClientSuccess = 'Cliente atualizado',
  UpdateClientError = 'Erro ao atualizar cliente',
  DeleteClientInit = 'Excluindo cliente',
  DeleteClientSuccess = 'Cliente excluído',
  DeleteClientError = 'Erro ao excluir cliente',
  RetrieveClientsInit = 'Obtendo clientes',
  RetrieveClientsSuccess = 'Clientes obtidos',
  RetrieveClientsError = 'Erro ao obter clientes',
  RetrieveClientsEmpty = 'Nenhum cliente encontrado',
  RetrieveClientSchemaInit = 'Obtendo esquema de clientes',
  RetrieveClientSchemaSuccess = 'Esquema de clientes obtido',
  RetrieveClientSchemaError = 'Erro ao obter esquema de clientes',

  // Customers
  CreateCustomerInit = 'Criando consumidor',
  CreateCustomerSuccess = 'Consumidor criado',
  CreateCustomerError = 'Erro ao criar consumidor',
  RetrieveCustomerInit = 'Obtendo consumidor',
  RetrieveCustomerSuccess = 'Consumidor obtido',
  RetrieveCustomerError = 'Erro ao obter consumidor',
  UpdateCustomerInit = 'Atualizando consumidor',
  UpdateCustomerSuccess = 'Consumidor atualizado',
  UpdateCustomerError = 'Erro ao atualizar consumidor',
  DeleteCustomerInit = 'Excluindo consumidor',
  DeleteCustomerSuccess = 'Consumidor excluído',
  DeleteCustomerError = 'Erro ao excluir consumidor',
  RetrieveCustomersInit = 'Obtendo consumidores',
  RetrieveCustomersSuccess = 'Consumidores obtidos',
  RetrieveCustomersError = 'Erro ao obter consumidores',
  RetrieveCustomersEmpty = 'Nenhum consumidor encontrado',
  RetrieveCustomerSchemaInit = 'Obtendo esquema de consumidores',
  RetrieveCustomerSchemaSuccess = 'Esquema de consumidores obtido',
  RetrieveCustomerSchemaError = 'Erro ao obter esquema de consumidores',

  // Agendas
  CreateAgendaInit = 'Criando agenda',
  CreateAgendaSuccess = 'Agenda criada',
  CreateAgendaError = 'Erro ao criar agenda',
  RetrieveAgendaInit = 'Obtendo agenda',
  RetrieveAgendaSuccess = 'Agenda obtida',
  RetrieveAgendaError = 'Erro ao obter agenda',
  UpdateAgendaInit = 'Atualizando agenda',
  UpdateAgendaSuccess = 'Agenda atualizada',
  UpdateAgendaError = 'Erro ao atualizar agenda',
  DeleteAgendaInit = 'Excluindo agenda',
  DeleteAgendaSuccess = 'Agenda excluída',
  DeleteAgendaError = 'Erro ao excluir agenda',
  RetrieveAgendasInit = 'Obtendo agendas',
  RetrieveAgendasSuccess = 'Agendas obtidas',
  RetrieveAgendasError = 'Erro ao obter agendas',
  RetrieveAgendasEmpty = 'Nenhuma agenda encontrada',
  RetrieveAgendaSchemaInit = 'Obtendo esquema de agendas',
  RetrieveAgendaSchemaSuccess = 'Esquema de agendas obtido',
  RetrieveAgendaSchemaError = 'Erro ao obter esquema de agendas',

  // Appointments
  CreateAppointmentInit = 'Criando compromisso',
  CreateAppointmentSuccess = 'Compromisso criado',
  CreateAppointmentError = 'Erro ao criar compromisso',
  RetrieveAppointmentInit = 'Obtendo compromisso',
  RetrieveAppointmentSuccess = 'Compromisso obtido',
  RetrieveAppointmentError = 'Erro ao obter compromisso',
  UpdateAppointmentInit = 'Atualizando compromisso',
  UpdateAppointmentSuccess = 'Compromisso atualizado',
  UpdateAppointmentError = 'Erro ao atualizar compromisso',
  DeleteAppointmentInit = 'Excluindo compromisso',
  DeleteAppointmentSuccess = 'Compromisso excluído',
  DeleteAppointmentError = 'Erro ao excluir compromisso',
  RetrieveAppointmentsInit = 'Obtendo compromissos',
  RetrieveAppointmentsSuccess = 'Compromissos obtidos',
  RetrieveAppointmentsError = 'Erro ao obter compromissos',
  RetrieveAppointmentsEmpty = 'Nenhum compromisso encontrado',
  RetrieveAppointmentSchemaInit = 'Obtendo esquema de compromissos',
  RetrieveAppointmentSchemaSuccess = 'Esquema de compromissos obtido',
  RetrieveAppointmentSchemaError = 'Erro ao obter esquema de compromissos',

  // Appointment logs
  RetrieveAppointmentLogsInit = 'Obtendo registros de compromisso',
  RetrieveAppointmentLogsSuccess = 'Registros de compromisso obtidos',
  RetrieveAppointmentLogsError = 'Erro ao obter registros de compromisso',
  RetrieveAppointmentLogsEmpty = 'Nenhum registro de compromisso encontrado',
  RetrieveAppointmentLogSchemaInit = 'Obtendo esquema de registros de compromissos',
  RetrieveAppointmentLogSchemaSuccess = 'Esquema de registros de compromissos obtido',
  RetrieveAppointmentLogSchemaError = 'Erro ao obter esquema de registros de compromissos',

  // Appointment slots
  CreateAppointmentSlotInit = 'Criando intervalo de agendamento',
  CreateAppointmentSlotSuccess = 'Intervalo de agendamento criado',
  CreateAppointmentSlotError = 'Erro ao criar intervalo de agendamento',
  RetrieveAppointmentSlotInit = 'Obtendo intervalo de agendamento',
  RetrieveAppointmentSlotSuccess = 'Intervalo de agendamento obtido',
  RetrieveAppointmentSlotError = 'Erro ao obter intervalo de agendamento',
  UpdateAppointmentSlotInit = 'Atualizando intervalo de agendamento',
  UpdateAppointmentSlotSuccess = 'Intervalo de agendamento atualizado',
  UpdateAppointmentSlotError = 'Erro ao atualizar intervalo de agendamento',
  DeleteAppointmentSlotInit = 'Excluindo intervalo de agendamento',
  DeleteAppointmentSlotSuccess = 'Intervalo de agendamento excluído',
  DeleteAppointmentSlotError = 'Erro ao excluir intervalo de agendamento',
  RetrieveAppointmentSlotsInit = 'Obtendo intervalos de agendamento',
  RetrieveAppointmentSlotsSuccess = 'Intervalos de agendamento obtidos',
  RetrieveAppointmentSlotsError = 'Erro ao obter intervalos de agendamento',
  RetrieveAppointmentSlotsEmpty = 'Nenhum intervalo de agendamento encontrado',
  RetrieveAppointmentSlotSchemaInit = 'Obtendo esquema de intervalos de agendamento',
  RetrieveAppointmentSlotSchemaSuccess = 'Esquema de intervalos de agendamento obtido',
  RetrieveAppointmentSlotSchemaError = 'Erro ao obter esquema de intervalos de agendamento',

  // Marketplaces
  CreateMarketplaceInit = 'Criando marketplace',
  CreateMarketplaceSuccess = 'Marketplace criado',
  CreateMarketplaceError = 'Erro ao criar marketplace',
  RetrieveMarketplaceInit = 'Obtendo marketplace',
  RetrieveMarketplaceSuccess = 'Marketplace obtido',
  RetrieveMarketplaceError = 'Erro ao obter marketplace',
  UpdateMarketplaceInit = 'Atualizando marketplace',
  UpdateMarketplaceSuccess = 'Marketplace atualizado',
  UpdateMarketplaceError = 'Erro ao atualizar marketplace',
  DeleteMarketplaceInit = 'Excluindo marketplace',
  DeleteMarketplaceSuccess = 'Marketplace excluído',
  DeleteMarketplaceError = 'Erro ao excluir marketplace',
  RetrieveMarketplacesInit = 'Obtendo marketplaces',
  RetrieveMarketplacesSuccess = 'Marketplaces obtidos',
  RetrieveMarketplacesError = 'Erro ao obter marketplaces',
  RetrieveMarketplacesEmpty = 'Nenhum marketplace encontrado',
  RetrieveMarketplaceSchemaInit = 'Obtendo esquema de marketplaces',
  RetrieveMarketplaceSchemaSuccess = 'Esquema de marketplaces obtido',
  RetrieveMarketplaceSchemaError = 'Erro ao obter esquema de marketplaces',
  MarkMarketplaceAsPendingInit = 'Marcando marketplace como pendente',
  MarkMarketplaceAsPendingSuccess = 'Marketplace marcado como pendente',
  MarkMarketplaceAsPendingError = 'Erro ao marcar marketplace como pendente',
  SubmitMarketplaceForReviewInit = 'Enviando marketplace para revisão',
  SubmitMarketplaceForReviewSuccess = 'Marketplace enviado para revisão',
  SubmitMarketplaceForReviewError = 'Erro ao enviar marketplace para revisão',
  ApproveMarketplaceInit = 'Aprovando marketplace',
  ApproveMarketplaceSuccess = 'Marketplace aprovado',
  ApproveMarketplaceError = 'Erro ao aprovar marketplace',
  RejectMarketplaceInit = 'Rejeitando marketplace',
  RejectMarketplaceSuccess = 'Marketplace rejeitado',
  RejectMarketplaceError = 'Erro ao rejeitar marketplace',
  CloseMarketplaceInit = 'Fechando marketplace definitivamente',
  CloseMarketplaceSuccess = 'Marketplace fechado definitivamente',
  CloseMarketplaceError = 'Erro ao fechar marketplace definitivamente',
  PutMarketplaceOnHoldInit = 'Colocando marketplace em modo de espera',
  PutMarketplaceOnHoldSuccess = 'Marketplace colocado em modo de espera',
  PutMarketplaceOnHoldError = 'Erro ao colocar marketplace em modo de espera',
  SuspendMarketplaceInit = 'Suspendendo marketplace',
  SuspendMarketplaceSuccess = 'Marketplace suspenso',
  SuspendMarketplaceError = 'Erro ao suspender marketplace',
  BanMarketplaceInit = 'Banindo marketplace',
  BanMarketplaceSuccess = 'Marketplace banido',
  BanMarketplaceError = 'Erro ao banir marketplace',
  ReactivateMarketplaceInit = 'Reativando marketplace',
  ReactivateMarketplaceSuccess = 'Marketplace reativado',
  ReactivateMarketplaceError = 'Erro ao reativar marketplace',

  // Marketplace logs
  RetrieveMarketplaceLogsInit = 'Obtendo registros de marketplace',
  RetrieveMarketplaceLogsSuccess = 'Registros de marketplace obtido',
  RetrieveMarketplaceLogsError = 'Erro ao obter registros de marketplace',
  RetrieveMarketplaceLogsEmpty = 'Nenhum registro de marketplace encontrado',
  RetrieveMarketplaceLogSchemaInit = 'Obtendo esquema de registros de marketplaces',
  RetrieveMarketplaceLogSchemaSuccess = 'Esquema de registros de marketplaces obtido',
  RetrieveMarketplaceLogSchemaError = 'Erro ao obter esquema de registros de marketplaces',

  // Stores
  CreateStoreInit = 'Criando loja',
  CreateStoreSuccess = 'Loja criada',
  CreateStoreError = 'Erro ao criar loja',
  RetrieveStoreInit = 'Obtendo loja',
  RetrieveStoreSuccess = 'Loja obtida',
  RetrieveStoreError = 'Erro ao obter loja',
  UpdateStoreInit = 'Atualizando loja',
  UpdateStoreSuccess = 'Loja atualizada',
  UpdateStoreError = 'Erro ao atualizar loja',
  DeleteStoreInit = 'Excluindo loja',
  DeleteStoreSuccess = 'Loja excluída',
  DeleteStoreError = 'Erro ao excluir loja',
  RetrieveStoresInit = 'Obtendo lojas',
  RetrieveStoresSuccess = 'Lojas obtidas',
  RetrieveStoresError = 'Erro ao obter lojas',
  RetrieveStoresEmpty = 'Nenhum loja encontrada',
  RetrieveStoreSchemaInit = 'Obtendo esquema de lojas',
  RetrieveStoreSchemaSuccess = 'Esquema de lojas obtido',
  RetrieveStoreSchemaError = 'Erro ao obter esquema de lojas',
  MarkStoreAsPendingInit = 'Marcando loja como pendente',
  MarkStoreAsPendingSuccess = 'Loja marcada como pendente',
  MarkStoreAsPendingError = 'Erro ao marcar loja como pendente',
  SubmitStoreForReviewInit = 'Enviando loja para revisão',
  SubmitStoreForReviewSuccess = 'Loja enviada para revisão',
  SubmitStoreForReviewError = 'Erro ao enviar loja para revisão',
  ApproveStoreInit = 'Aprovando loja',
  ApproveStoreSuccess = 'Loja aprovada',
  ApproveStoreError = 'Erro ao aprovar loja',
  RejectStoreInit = 'Rejeitando loja',
  RejectStoreSuccess = 'Loja rejeitada',
  RejectStoreError = 'Erro ao rejeitar loja',
  CloseStoreInit = 'Fechando loja definitivamente',
  CloseStoreSuccess = 'Loja fechada definitivamente',
  CloseStoreError = 'Erro ao fechar loja definitivamente',
  PutStoreOnHoldInit = 'Colocando loja em modo de espera',
  PutStoreOnHoldSuccess = 'Loja colocada em modo de espera',
  PutStoreOnHoldError = 'Erro ao colocar loja em modo de espera',
  SuspendStoreInit = 'Suspendendo loja',
  SuspendStoreSuccess = 'Loja suspensa',
  SuspendStoreError = 'Erro ao suspender loja',
  BanStoreInit = 'Banindo loja',
  BanStoreSuccess = 'Loja banida',
  BanStoreError = 'Erro ao banir loja',
  ReactivateStoreInit = 'Reativando loja',
  ReactivateStoreSuccess = 'Loja reativada',
  ReactivateStoreError = 'Erro ao reativar loja',

  // Store logs
  RetrieveStoreLogsInit = 'Obtendo registros de loja',
  RetrieveStoreLogsSuccess = 'Registros de loja obtido',
  RetrieveStoreLogsError = 'Erro ao obter registros de loja',
  RetrieveStoreLogsEmpty = 'Nenhum registro de loja encontrado',
  RetrieveStoreLogSchemaInit = 'Obtendo esquema de registros de lojas',
  RetrieveStoreLogSchemaSuccess = 'Esquema de registros de lojas obtido',
  RetrieveStoreLogSchemaError = 'Erro ao obter esquema de registros de lojas',

  // Accounts
  CreateAccountInit = 'Criando conta',
  CreateAccountSuccess = 'Conta criada',
  CreateAccountError = 'Erro ao criar conta',
  RetrieveAccountInit = 'Obtendo conta',
  RetrieveAccountSuccess = 'Conta obtida',
  RetrieveAccountError = 'Erro ao obter conta',
  UpdateAccountInit = 'Atualizando conta',
  UpdateAccountSuccess = 'Conta atualizada',
  UpdateAccountError = 'Erro ao atualizar conta',
  DeleteAccountInit = 'Excluindo conta',
  DeleteAccountSuccess = 'Conta excluída',
  DeleteAccountError = 'Erro ao excluir conta',
  RetrieveAccountsInit = 'Obtendo contas',
  RetrieveAccountsSuccess = 'Contas obtidas',
  RetrieveAccountsError = 'Erro ao obter contas',
  RetrieveAccountsEmpty = 'Nenhuma conta encontrada',
  RetrieveAccountSchemaInit = 'Obtendo esquema de contas',
  RetrieveAccountSchemaSuccess = 'Esquema de contas obtido',
  RetrieveAccountSchemaError = 'Erro ao obter esquema de contas',
  ChangeAccountCredentialsInit = 'Alterando credenciais de conta',
  ChangeAccountCredentialsSuccess = 'Credenciais de conta alteradas',
  ChangeAccountCredentialsError = 'Erro ao alterar credenciais de conta',
  SubmitAccountForReviewInit = 'Enviando conta para revisão',
  SubmitAccountForReviewSuccess = 'Conta enviada para revisão',
  SubmitAccountForReviewError = 'Erro ao enviar conta para revisão',
  ApproveAccountInit = 'Aprovando conta',
  ApproveAccountSuccess = 'Conta aprovada',
  ApproveAccountError = 'Erro ao aprovar conta',
  RejectAccountInit = 'Rejeitando conta',
  RejectAccountSuccess = 'Conta rejeitada',
  RejectAccountError = 'Erro ao rejeitar conta',
  MarkAccountAsPendingInit = 'Marcando conta como pendente',
  MarkAccountAsPendingSuccess = 'Conta marcada como pendente',
  MarkAccountAsPendingError = 'Erro ao marcar conta como pendente',
  PutAccountOnHoldInit = 'Colocando conta em espera',
  PutAccountOnHoldSuccess = 'Conta colocada em espera',
  PutAccountOnHoldError = 'Erro ao colocar conta em espera',
  RetireAccountInit = 'Descontinuando conta',
  RetireAccountSuccess = 'Conta descontinuada',
  RetireAccountError = 'Erro ao descontinuar conta',
  CloseAccountInit = 'Fechando conta',
  CloseAccountSuccess = 'Conta fechada',
  CloseAccountError = 'Erro ao fechar conta',
  SuspendAccountInit = 'Suspendendo conta',
  SuspendAccountSuccess = 'Conta suspensa',
  SuspendAccountError = 'Erro ao suspender conta',
  BanAccountInit = 'Banindo conta',
  BanAccountSuccess = 'Conta banida',
  BanAccountError = 'Erro ao banir conta',
  FreezeAccountInit = 'Congelando conta',
  FreezeAccountSuccess = 'Conta congelada',
  FreezeAccountError = 'Erro ao congelar conta',
  ReactivateAccountInit = 'Reativando conta',
  ReactivateAccountSuccess = 'Conta reativada',
  ReactivateAccountError = 'Erro ao reativar conta',

  // Account logs
  RetrieveAccountLogsInit = 'Obtendo registros de conta',
  RetrieveAccountLogsSuccess = 'Registros de conta obtido',
  RetrieveAccountLogsError = 'Erro ao obter registros de conta',
  RetrieveAccountLogsEmpty = 'Nenhum registro de conta encontrado',
  RetrieveAccountLogSchemaInit = 'Obtendo esquema de registros de contas',
  RetrieveAccountLogSchemaSuccess = 'Esquema de registros de contas obtido',
  RetrieveAccountLogSchemaError = 'Erro ao obter esquema de registros de contas',

  // Account docs
  CreateAccountDocInit = 'Criando documento de conta',
  CreateAccountDocSuccess = 'Documento de conta criado',
  CreateAccountDocError = 'Erro ao criar documento de conta',
  RetrieveAccountDocInit = 'Obtendo documento de conta',
  RetrieveAccountDocSuccess = 'Documento de conta obtido',
  RetrieveAccountDocError = 'Erro ao obter documento de conta',
  UpdateAccountDocInit = 'Atualizando documento de conta',
  UpdateAccountDocSuccess = 'Documento de conta atualizado',
  UpdateAccountDocError = 'Erro ao atualizar documento de conta',
  DeleteAccountDocInit = 'Excluindo documento de conta',
  DeleteAccountDocSuccess = 'Documento de conta excluído',
  DeleteAccountDocError = 'Erro ao excluir documento de conta',
  RetrieveAccountDocsInit = 'Obtendo documentos de conta',
  RetrieveAccountDocsSuccess = 'Documentos de conta obtidos',
  RetrieveAccountDocsError = 'Erro ao obter documentos de conta',
  RetrieveAccountDocsEmpty = 'Nenhum documento de conta encontrado',
  RetrieveAccountDocSchemaInit = 'Obtendo esquema de documentos de conta',
  RetrieveAccountDocSchemaSuccess = 'Esquema de documentos de conta obtido',
  RetrieveAccountDocSchemaError = 'Erro ao obter esquema de documentos de conta',
  SubmitAccountDocForReviewInit = 'Enviando documento para revisão',
  SubmitAccountDocForReviewSuccess = 'Documento enviado para revisão',
  SubmitAccountDocForReviewError = 'Erro ao enviar documento para revisão',

  // Account doc logs
  RetrieveAccountDocLogsInit = 'Obtendo registros de documento de conta',
  RetrieveAccountDocLogsSuccess = 'Registros de documento de conta obtido',
  RetrieveAccountDocLogsError = 'Erro ao obter registros de documento de conta',
  RetrieveAccountDocLogsEmpty = 'Nenhum registro de documento de conta encontrado',
  RetrieveAccountDocLogSchemaInit = 'Obtendo esquema de registros de documentos de conta',
  RetrieveAccountDocLogSchemaSuccess = 'Esquema de registros de documentos de conta obtido',
  RetrieveAccountDocLogSchemaError = 'Erro ao obter esquema de registros de documentos de conta',

  // Transactions
  RetrieveTransactionInit = 'Obtendo transação',
  RetrieveTransactionSuccess = 'Transação obtida',
  RetrieveTransactionError = 'Erro ao obter transação',
  RetrieveTransactionsInit = 'Obtendo transações',
  RetrieveTransactionsSuccess = 'Transações obtidas',
  RetrieveTransactionsError = 'Erro ao obter transações',
  RetrieveTransactionsEmpty = 'Nenhuma transação encontrada',
  RetrieveTransactionSchemaInit = 'Obtendo esquema de transações',
  RetrieveTransactionSchemaSuccess = 'Esquema de transações obtido',
  RetrieveTransactionSchemaError = 'Erro ao obter esquema de transações',

  // Transactions entries
  RetrieveTransactionEntryInit = 'Obtendo entrada de transação',
  RetrieveTransactionEntrySuccess = 'Entrade de transação obtida',
  RetrieveTransactionEntryError = 'Erro ao obter entrada de transação',
  RetrieveTransactionEntriesInit = 'Obtendo entradas de transações',
  RetrieveTransactionEntriesSuccess = 'Entradas de transações obtidas',
  RetrieveTransactionEntriesError = 'Erro ao obter entradas de transações',
  RetrieveTransactionEntriesEmpty = 'Nenhuma entrada de transação encontrada',
  RetrieveTransactionEntrySchemaInit = 'Obtendo esquema de entradas de transações',
  RetrieveTransactionEntrySchemaSuccess = 'Esquema de entradas de transações obtido',
  RetrieveTransactionEntrySchemaError = 'Erro ao obter esquema de entradas de transações',

  // Billing settings
  CreateBillingSettingInit = 'Criando configuração de cobrança',
  CreateBillingSettingSuccess = 'Configuração de cobrança criada',
  CreateBillingSettingError = 'Erro ao criar configuração de cobrança',
  RetrieveBillingSettingInit = 'Obtendo configuração de cobrança',
  RetrieveBillingSettingSuccess = 'Configuração de cobrança obtida',
  RetrieveBillingSettingError = 'Erro ao obter configuração de cobrança',
  UpdateBillingSettingInit = 'Atualizando configuração de cobrança',
  UpdateBillingSettingSuccess = 'Configuração de cobrança atualizada',
  UpdateBillingSettingError = 'Erro ao atualizar configuração de cobrança',
  DeleteBillingSettingInit = 'Excluindo configuração de cobrança',
  DeleteBillingSettingSuccess = 'Configuração de cobrança excluída',
  DeleteBillingSettingError = 'Erro ao excluir configuração de cobrança',
  RetrieveBillingSettingsInit = 'Obtendo configurações de cobrança',
  RetrieveBillingSettingsSuccess = 'Configurações de cobrança obtidas',
  RetrieveBillingSettingsError = 'Erro ao obter configurações de cobrança',
  RetrieveBillingSettingsEmpty = 'Nenhuma configuração de cobrança encontrada',
  RetrieveBillingSettingSchemaInit = 'Obtendo esquema de configurações de cobrança',
  RetrieveBillingSettingSchemaSuccess = 'Esquema de configurações de cobrança obtido',
  RetrieveBillingSettingSchemaError = 'Erro ao obter esquema de configurações de cobrança',

  // Fees
  CreateFeeInit = 'Criando taxa',
  CreateFeeSuccess = 'Taxa criada',
  CreateFeeError = 'Erro ao criar taxa',
  RetrieveFeeInit = 'Obtendo taxa',
  RetrieveFeeSuccess = 'Taxa obtida',
  RetrieveFeeError = 'Erro ao obter taxa',
  UpdateFeeInit = 'Atualizando taxa',
  UpdateFeeSuccess = 'Taxa atualizada',
  UpdateFeeError = 'Erro ao atualizar taxa',
  DeleteFeeInit = 'Excluindo taxa',
  DeleteFeeSuccess = 'Taxa excluída',
  DeleteFeeError = 'Erro ao excluir taxa',
  RetrieveFeesInit = 'Obtendo taxas',
  RetrieveFeesSuccess = 'Taxas obtidas',
  RetrieveFeesError = 'Erro ao obter taxas',
  RetrieveFeesEmpty = 'Nenhuma taxa encontrada',
  RetrieveFeeSchemaInit = 'Obtendo esquema de taxas',
  RetrieveFeeSchemaSuccess = 'Esquema de taxas obtido',
  RetrieveFeeSchemaError = 'Erro ao obter esquema de taxas',
  ActivateFeeInit = 'Ativando taxa',
  ActivateFeeSuccess = 'Taxa ativada',
  ActivateFeeError = 'Erro ao ativar taxa',
  PauseFeeInit = 'Pausando taxa',
  PauseFeeSuccess = 'Taxa pausada',
  PauseFeeError = 'Erro ao pausar taxa',
  RetireFeeInit = 'Descontinuando taxa',
  RetireFeeSuccess = 'Taxa descontinuada',
  RetireFeeError = 'Erro ao descontinuar taxa',
  ReactivateFeeInit = 'Reativando taxa',
  ReactivateFeeSuccess = 'Taxa reativada',
  ReactivateFeeError = 'Erro ao reativar taxa',

  // Fee logs
  RetrieveFeeLogsInit = 'Obtendo registros de taxa',
  RetrieveFeeLogsSuccess = 'Registros de taxa obtido',
  RetrieveFeeLogsError = 'Erro ao obter registros de taxa',
  RetrieveFeeLogsEmpty = 'Nenhum registro de taxa encontrado',
  RetrieveFeeLogSchemaInit = 'Obtendo esquema de registros de taxas',
  RetrieveFeeLogSchemaSuccess = 'Esquema de registros de taxas obtido',
  RetrieveFeeLogSchemaError = 'Erro ao obter esquema de registros de taxas',

  // Orders
  CreateOrderInit = 'Criando pedido',
  CreateOrderSuccess = 'Pedido criado',
  CreateOrderError = 'Erro ao criar pedido',
  RetrieveOrderInit = 'Obtendo pedido',
  RetrieveOrderSuccess = 'Pedido obtido',
  RetrieveOrderError = 'Erro ao obter pedido',
  UpdateOrderInit = 'Atualizando pedido',
  UpdateOrderSuccess = 'Pedido atualizado',
  UpdateOrderError = 'Erro ao atualizar pedido',
  DeleteOrderInit = 'Excluindo pedido',
  DeleteOrderSuccess = 'Pedido excluído',
  DeleteOrderError = 'Erro ao excluir pedido',
  RetrieveOrdersInit = 'Obtendo pedidos',
  RetrieveOrdersSuccess = 'Pedidos obtidos',
  RetrieveOrdersError = 'Erro ao obter pedidos',
  RetrieveOrdersEmpty = 'Nenhum pedido encontrado',
  RetrieveOrderSchemaInit = 'Obtendo esquema de pedidos',
  RetrieveOrderSchemaSuccess = 'Esquema de pedidos obtido',
  RetrieveOrderSchemaError = 'Erro ao obter esquema de pedidos',
  CancelOrderInit = 'Cancelando pedido',
  CancelOrderSuccess = 'Pedido cancelado',
  CancelOrderError = 'Erro ao cancelar pedido',
  DeclineOrderInit = 'Rejeitando pedido',
  DeclineOrderSuccess = 'Pedido rejeitado',
  DeclineOrderError = 'Erro ao rejeitar pedido',
  ConfirmOrderInit = 'Confirmando pedido',
  ConfirmOrderSuccess = 'Pedido confirmado',
  ConfirmOrderError = 'Erro ao confirmar pedido',
  FulfillOrderInit = 'Marcando pedido como atendido',
  FulfillOrderSuccess = 'Pedido marcado como atendido',
  FulfillOrderError = 'Erro ao marcar pedido como atendido',

  // Order logs
  RetrieveOrderLogsInit = 'Obtendo registros de pedido',
  RetrieveOrderLogsSuccess = 'Registros de pedido obtido',
  RetrieveOrderLogsError = 'Erro ao obter registros de pedido',
  RetrieveOrderLogsEmpty = 'Nenhum registro de pedido encontrado',
  RetrieveOrderLogSchemaInit = 'Obtendo esquema de registros de pedidos',
  RetrieveOrderLogSchemaSuccess = 'Esquema de registros de pedidos obtido',
  RetrieveOrderLogSchemaError = 'Erro ao obter esquema de registros de pedidos',

  // Order fulfillments
  CreateOrderFulfillmentInit = 'Criando atendimento',
  CreateOrderFulfillmentSuccess = 'Atendimento criado',
  CreateOrderFulfillmentError = 'Erro ao criar atendimento',
  RetrieveOrderFulfillmentInit = 'Obtendo atendimento',
  RetrieveOrderFulfillmentSuccess = 'Atendimento obtido',
  RetrieveOrderFulfillmentError = 'Erro ao obter atendimento',
  UpdateOrderFulfillmentInit = 'Atualizando atendimento',
  UpdateOrderFulfillmentSuccess = 'Atendimento atualizado',
  UpdateOrderFulfillmentError = 'Erro ao atualizar atendimento',
  DeleteOrderFulfillmentInit = 'Excluindo atendimento',
  DeleteOrderFulfillmentSuccess = 'Atendimento excluído',
  DeleteOrderFulfillmentError = 'Erro ao excluir atendimento',
  RetrieveOrderFulfillmentsInit = 'Obtendo atendimentos',
  RetrieveOrderFulfillmentsSuccess = 'Atendimentos obtidos',
  RetrieveOrderFulfillmentsError = 'Erro ao obter atendimentos',
  RetrieveOrderFulfillmentsEmpty = 'Nenhuma atendimento encontrada',
  RetrieveOrderFulfillmentSchemaInit = 'Obtendo esquema de atendimentos',
  RetrieveOrderFulfillmentSchemaSuccess = 'Esquema de atendimentos obtido',
  RetrieveOrderFulfillmentSchemaError = 'Erro ao obter esquema de atendimentos',
  RegisterOrderFulfillmentInit = 'Registrando atendimento',
  RegisterOrderFulfillmentSuccess = 'Atendimento registrado',
  RegisterOrderFulfillmentError = 'Erro ao registrar atendimento',
  CancelOrderFulfillmentInit = 'Cancelando atendimento',
  CancelOrderFulfillmentSuccess = 'Atendimento cancelado',
  CancelOrderFulfillmentError = 'Erro ao cancelar atendimento',

  // Order fulfillment logs
  RetrieveOrderFulfillmentLogsInit = 'Obtendo registros de atendimento',
  RetrieveOrderFulfillmentLogsSuccess = 'Registros de atendimento obtidos',
  RetrieveOrderFulfillmentLogsError = 'Erro ao obter registros de atendimento',
  RetrieveOrderFulfillmentLogsEmpty = 'Nenhum registro de atendimento encontrado',
  RetrieveOrderFulfillmentLogSchemaInit = 'Obtendo esquema de registros de atendimentos',
  RetrieveOrderFulfillmentLogSchemaSuccess = 'Esquema de registros de atendimentos obtido',
  RetrieveOrderFulfillmentLogSchemaError = 'Erro ao obter esquema de registros de atendimentos',

  // Order cancellations
  CreateOrderCancellationInit = 'Criando cancelamento',
  CreateOrderCancellationSuccess = 'Cancelamento criado',
  CreateOrderCancellationError = 'Erro ao criar cancelamento',
  RetrieveOrderCancellationInit = 'Obtendo cancelamento',
  RetrieveOrderCancellationSuccess = 'Cancelamento obtido',
  RetrieveOrderCancellationError = 'Erro ao obter cancelamento',
  UpdateOrderCancellationInit = 'Atualizando cancelamento',
  UpdateOrderCancellationSuccess = 'Cancelamento atualizado',
  UpdateOrderCancellationError = 'Erro ao atualizar cancelamento',
  DeleteOrderCancellationInit = 'Excluindo cancelamento',
  DeleteOrderCancellationSuccess = 'Cancelamento excluído',
  DeleteOrderCancellationError = 'Erro ao excluir cancelamento',
  RetrieveOrderCancellationsInit = 'Obtendo cancelamentos',
  RetrieveOrderCancellationsSuccess = 'Cancelamentos obtidos',
  RetrieveOrderCancellationsError = 'Erro ao obter cancelamentos',
  RetrieveOrderCancellationsEmpty = 'Nenhuma cancelamento encontrado',
  RetrieveOrderCancellationSchemaInit = 'Obtendo esquema de cancelamentos',
  RetrieveOrderCancellationSchemaSuccess = 'Esquema de cancelamentos obtido',
  RetrieveOrderCancellationSchemaError = 'Erro ao obter esquema de cancelamentos',
  SubmitOrderCancellationRequestInit = 'Solicitando cancelamento',
  SubmitOrderCancellationRequestSuccess = 'Cancelamento solicitado',
  SubmitOrderCancellationRequestError = 'Erro ao solicitar cancelamento',
  AcceptOrderCancellationInit = 'Aceitando cancelamento',
  AcceptOrderCancellationSuccess = 'Cancelamento aceito',
  AcceptOrderCancellationError = 'Erro ao aceitar cancelamento',
  DenyOrderCancellationInit = 'Negando cancelamento',
  DenyOrderCancellationSuccess = 'Cancelamento negado',
  DenyOrderCancellationError = 'Erro ao negar cancelamento',
  CancelOrderCancellationInit = 'Cancelando cancelamento',
  CancelOrderCancellationSuccess = 'Cancelamento cancelado',
  CancelOrderCancellationError = 'Erro ao cancelar cancelamento',

  // Order cancellation logs
  RetrieveOrderCancellationLogsInit = 'Obtendo registros de cancelamento',
  RetrieveOrderCancellationLogsSuccess = 'Registros de cancelamento obtidos',
  RetrieveOrderCancellationLogsError = 'Erro ao obter registros de cancelamento',
  RetrieveOrderCancellationLogsEmpty = 'Nenhum registro de cancelamento encontrado',
  RetrieveOrderCancellationLogSchemaInit = 'Obtendo esquema de registros de devoluções',
  RetrieveOrderCancellationLogSchemaSuccess = 'Esquema de registros de devoluções obtido',
  RetrieveOrderCancellationLogSchemaError = 'Erro ao obter esquema de registros de devoluções',

  // Order returns
  CreateOrderReturnInit = 'Criando devolução',
  CreateOrderReturnSuccess = 'Devolução criada',
  CreateOrderReturnError = 'Erro ao criar devolução',
  RetrieveOrderReturnInit = 'Obtendo devolução',
  RetrieveOrderReturnSuccess = 'Devolução obtida',
  RetrieveOrderReturnError = 'Erro ao obter devolução',
  UpdateOrderReturnInit = 'Atualizando devolução',
  UpdateOrderReturnSuccess = 'Devolução atualizada',
  UpdateOrderReturnError = 'Erro ao atualizar devolução',
  DeleteOrderReturnInit = 'Excluindo devolução',
  DeleteOrderReturnSuccess = 'Devolução excluída',
  DeleteOrderReturnError = 'Erro ao excluir devolução',
  RetrieveOrderReturnsInit = 'Obtendo devoluções',
  RetrieveOrderReturnsSuccess = 'Devoluções obtidas',
  RetrieveOrderReturnsError = 'Erro ao obter devoluções',
  RetrieveOrderReturnsEmpty = 'Nenhuma devolução encontrada',
  RetrieveOrderReturnSchemaInit = 'Obtendo esquema de devoluções',
  RetrieveOrderReturnSchemaSuccess = 'Esquema de devoluções obtido',
  RetrieveOrderReturnSchemaError = 'Erro ao obter esquema de devoluções',
  CancelOrderReturnInit = 'Cancelando devolução',
  CancelOrderReturnSuccess = 'Devolução cancelada',
  CancelOrderReturnError = 'Erro ao cancelar devolução',
  ConfirmOrderReturnInit = 'Confirmando devolução',
  ConfirmOrderReturnSuccess = 'Devolução confirmada',
  ConfirmOrderReturnError = 'Erro ao confirmar devolução',
  SubmitOrderReturnRequestInit = 'Solicitando devolução',
  SubmitOrderReturnRequestSuccess = 'Devolução solicitada',
  SubmitOrderReturnRequestError = 'Erro ao solicitar devolução',
  AcceptOrderReturnInit = 'Aceitando devolução',
  AcceptOrderReturnSuccess = 'Devolução aceita',
  AcceptOrderReturnError = 'Erro ao aceitar devolução',
  DenyOrderReturnInit = 'Negando devolução',
  DenyOrderReturnSuccess = 'Devolução negada',
  DenyOrderReturnError = 'Erro ao negar devolução',
  MarkOrderReturnAsInTransitInit = 'Marcando devolução como em trânsito',
  MarkOrderReturnAsInTransitSuccess = 'Devolução marcado como em trânsito',
  MarkOrderReturnAsInTransitError = 'Erro ao marcar devolução como em trânsito',
  MarkOrderReturnAsOutForDeliveryInit = 'Marcando devolução como fora para entrega',
  MarkOrderReturnAsOutForDeliverySuccess = 'Devolução marcado como fora para entrega',
  MarkOrderReturnAsOutForDeliveryError = 'Erro ao marcar devolução como fora para entrega',
  MarkOrderReturnAsFailedInit = 'Marcando devolução como mal sucedida',
  MarkOrderReturnAsFailedSuccess = 'Devolução marcada como mal sucedida',
  MarkOrderReturnAsFailedError = 'Erro ao marcar devolução como mal sucedida',
  MarkOrderReturnAsLostInit = 'Marcando devolução como perdida',
  MarkOrderReturnAsLostSuccess = 'Devolução marcada como perdida',
  MarkOrderReturnAsLostError = 'Erro ao marcar devolução como perdida',

  // Order return logs
  RetrieveOrderReturnLogsInit = 'Obtendo registros de devolução',
  RetrieveOrderReturnLogsSuccess = 'Registros de devolução obtidos',
  RetrieveOrderReturnLogsError = 'Erro ao obter registros de devolução',
  RetrieveOrderReturnLogsEmpty = 'Nenhum registro de devolução encontrado',
  RetrieveOrderReturnLogSchemaInit = 'Obtendo esquema de registros de devoluções',
  RetrieveOrderReturnLogSchemaSuccess = 'Esquema de registros de devoluções obtido',
  RetrieveOrderReturnLogSchemaError = 'Erro ao obter esquema de registros de devoluções',

  // Invoices
  CreateInvoiceInit = 'Criando fatura',
  CreateInvoiceSuccess = 'Fatura criada',
  CreateInvoiceError = 'Erro ao criar fatura',
  RetrieveInvoiceInit = 'Obtendo fatura',
  RetrieveInvoiceSuccess = 'Fatura obtida',
  RetrieveInvoiceError = 'Erro ao obter fatura',
  UpdateInvoiceInit = 'Atualizando fatura',
  UpdateInvoiceSuccess = 'Fatura atualizada',
  UpdateInvoiceError = 'Erro ao atualizar fatura',
  DeleteInvoiceInit = 'Excluindo fatura',
  DeleteInvoiceSuccess = 'Fatura excluída',
  DeleteInvoiceError = 'Erro ao excluir fatura',
  RetrieveInvoicesInit = 'Obtendo faturas',
  RetrieveInvoicesSuccess = 'Faturas obtidas',
  RetrieveInvoicesError = 'Erro ao obter faturas',
  RetrieveInvoicesEmpty = 'Nenhuma fatura encontrada',
  RetrieveInvoiceSchemaInit = 'Obtendo esquema de faturas',
  RetrieveInvoiceSchemaSuccess = 'Esquema de faturas obtido',
  RetrieveInvoiceSchemaError = 'Erro ao obter esquema de faturas',

  // Product categories
  CreateProductCategoryInit = 'Criando categoria de produto',
  CreateProductCategorySuccess = 'Categoria de produto criada',
  CreateProductCategoryError = 'Erro ao criar categoria de produto',
  RetrieveProductCategoryInit = 'Obtendo categoria de produto',
  RetrieveProductCategorySuccess = 'Categoria de produto obtida',
  RetrieveProductCategoryError = 'Erro ao obter categoria de produto',
  UpdateProductCategoryInit = 'Atualizando categoria de produto',
  UpdateProductCategorySuccess = 'Categoria de produto atualizada',
  UpdateProductCategoryError = 'Erro ao atualizar categoria de produto',
  DeleteProductCategoryInit = 'Excluindo categoria de produto',
  DeleteProductCategorySuccess = 'Categoria de produto excluída',
  DeleteProductCategoryError = 'Erro ao excluir categoria de produto',
  RetrieveProductCategoriesInit = 'Obtendo categorias de produtos',
  RetrieveProductCategoriesSuccess = 'Categorias de produtos obtidas',
  RetrieveProductCategoriesError = 'Erro ao obter categorias de produtos',
  RetrieveProductCategoriesEmpty = 'Nenhuma categoria de produto encontrada',
  RetrieveProductCategorySchemaInit = 'Obtendo esquema de categorias de produtos',
  RetrieveProductCategorySchemaSuccess = 'Esquema de categoria de produtos obtido',
  RetrieveProductCategorySchemaError = 'Erro ao obter esquema de categorias de produtos',

  // Product brands
  CreateProductBrandInit = 'Criando marca de produto',
  CreateProductBrandSuccess = 'Marca de produto criada',
  CreateProductBrandError = 'Erro ao criar marca de produto',
  RetrieveProductBrandInit = 'Obtendo marca de produto',
  RetrieveProductBrandSuccess = 'Marca de produto obtida',
  RetrieveProductBrandError = 'Erro ao obter marca de produto',
  UpdateProductBrandInit = 'Atualizando marca de produto',
  UpdateProductBrandSuccess = 'Marca de produto atualizada',
  UpdateProductBrandError = 'Erro ao atualizar marca de produto',
  DeleteProductBrandInit = 'Excluindo marca de produto',
  DeleteProductBrandSuccess = 'Marca de produto excluída',
  DeleteProductBrandError = 'Erro ao excluir marca de produto',
  RetrieveProductBrandsInit = 'Obtendo marcas de produtos',
  RetrieveProductBrandsSuccess = 'Marcas de produtos obtidas',
  RetrieveProductBrandsError = 'Erro ao obter marcas de produtos',
  RetrieveProductBrandsEmpty = 'Nenhuma marca de produto encontrada',
  RetrieveProductBrandSchemaInit = 'Obtendo esquema de marcas de produtos',
  RetrieveProductBrandSchemaSuccess = 'Esquema de marcas de produtos obtido',
  RetrieveProductBrandSchemaError = 'Erro ao obter esquema de marcas de produtos',

  // Products
  CreateProductInit = 'Criando produto',
  CreateProductSuccess = 'Produto criado',
  CreateProductError = 'Erro ao criar produto',
  RetrieveProductInit = 'Obtendo produto',
  RetrieveProductSuccess = 'Produto obtido',
  RetrieveProductError = 'Erro ao obter produto',
  UpdateProductInit = 'Atualizando produto',
  UpdateProductSuccess = 'Produto atualizado',
  UpdateProductError = 'Erro ao atualizar produto',
  DeleteProductInit = 'Excluindo produto',
  DeleteProductSuccess = 'Produto excluído',
  DeleteProductError = 'Erro ao excluir produto',
  RetrieveProductsInit = 'Obtendo produtos',
  RetrieveProductsSuccess = 'Produtos obtidos',
  RetrieveProductsError = 'Erro ao obter produtos',
  RetrieveProductsEmpty = 'Nenhum produto encontrado',
  RetrieveRecentProductsInit = 'Obtendo produtos recentes',
  RetrieveRecentProductsSuccess = 'Produtos recentes obtidos',
  RetrieveRecentProductsError = 'Erro ao obter produtos recentes',
  RetrieveRecentProductsEmpty = 'Nenhum produto recente encontrado',
  RetrieveProductSchemaInit = 'Obtendo esquema de produtos',
  RetrieveProductSchemaSuccess = 'Esquema de produtos obtido',
  RetrieveProductSchemaError = 'Erro ao obter esquema de produtos',
  SubmitProductForReviewInit = 'Enviando produto para revisão',
  SubmitProductForReviewSuccess = 'Produto enviado para revisão',
  SubmitProductForReviewError = 'Erro ao enviar produto para revisão',
  ApproveProductInit = 'Aprovando produto',
  ApproveProductSuccess = 'Produto aprovado',
  ApproveProductError = 'Erro ao aprovar produto',
  RejectProductInit = 'Rejeitando produto',
  RejectProductSuccess = 'Produto rejeitado',
  RejectProductError = 'Erro ao rejeitar produto',
  PutProductOnHoldInit = 'Colocando produto em espera',
  PutProductOnHoldSuccess = 'Produto colocado em espera',
  PutProductOnHoldError = 'Erro ao colocar produto em espera',
  RetireProductInit = 'Descontinuando produto',
  RetireProductSuccess = 'Produto descontinuado',
  RetireProductError = 'Erro ao descontinuar produto',
  SuspendProductInit = 'Suspendendo produto',
  SuspendProductSuccess = 'Produto suspenso',
  SuspendProductError = 'Erro ao suspender produto',
  BanProductInit = 'Banindo produto',
  BanProductSuccess = 'Produto banido',
  BanProductError = 'Erro ao banir produto',
  ReactivateProductInit = 'Reativando produto',
  ReactivateProductSuccess = 'Produto reativado',
  ReactivateProductError = 'Erro ao reativar produto',

  // Product logs
  RetrieveProductLogsInit = 'Obtendo registros de produto',
  RetrieveProductLogsSuccess = 'Registros de produto obtidos',
  RetrieveProductLogsError = 'Erro ao obter registros de produto',
  RetrieveProductLogsEmpty = 'Nenhum registro de produto encontrado',
  RetrieveProductLogSchemaInit = 'Obtendo esquema de registros de produtos',
  RetrieveProductLogSchemaSuccess = 'Esquema de registros de produtos obtido',
  RetrieveProductLogSchemaError = 'Erro ao obter esquema de registros de produtos',

  // Products variants
  CreateProductVariantInit = 'Criando variação de produto',
  CreateProductVariantSuccess = 'Variação de produto criada',
  CreateProductVariantError = 'Erro ao criar variação de produto',
  RetrieveProductVariantInit = 'Obtendo variação de produto',
  RetrieveProductVariantSuccess = 'Variação de produto obtida',
  RetrieveProductVariantError = 'Erro ao obter variação de produto',
  UpdateProductVariantInit = 'Atualizando variação de produto',
  UpdateProductVariantSuccess = 'Variação de produto atualizada',
  UpdateProductVariantError = 'Erro ao atualizar variação de produto',
  DeleteProductVariantInit = 'Excluindo variação de produto',
  DeleteProductVariantSuccess = 'Variação de produto excluída',
  DeleteProductVariantError = 'Erro ao excluir variação de produto',
  RetrieveProductVariantsInit = 'Obtendo variações de produto',
  RetrieveProductVariantsSuccess = 'Variações de produto obtidas',
  RetrieveProductVariantsError = 'Erro ao obter variações de produto',
  RetrieveProductVariantsEmpty = 'Nenhuma variação de produto encontrada',
  RetrieveProductVariantSchemaInit = 'Obtendo esquema de variações de produtos',
  RetrieveProductVariantSchemaSuccess = 'Esquema de variações de produtos obtido',
  RetrieveProductVariantSchemaError = 'Erro ao obter esquema de variações de produtos',
  CreateProductVariantsInit = 'Criando variações de produto',
  CreateProductVariantsSuccess = 'Variações de produto criadas',
  CreateProductVariantsError = 'Erro ao criar variações de produto',
  UpdateProductVariantsInit = 'Atualizando variações de produto',
  UpdateProductVariantsSuccess = 'Variações de produto atualizadas',
  UpdateProductVariantsError = 'Erro ao atualizar variações de produto',

  // Inventory products
  CreateInventoryProductInit = 'Criando produto de inventário',
  CreateInventoryProductSuccess = 'Produto de inventário criado',
  CreateInventoryProductError = 'Erro ao criar produto de inventário',
  RetrieveInventoryProductInit = 'Obtendo produto de inventário',
  RetrieveInventoryProductSuccess = 'Produto de inventário obtido',
  RetrieveInventoryProductError = 'Erro ao obter produto de inventário',
  UpdateInventoryProductInit = 'Atualizando produto de inventário',
  UpdateInventoryProductSuccess = 'Produto de inventário atualizado',
  UpdateInventoryProductError = 'Erro ao atualizar produto de inventário',
  DeleteInventoryProductInit = 'Excluindo produto de inventário',
  DeleteInventoryProductSuccess = 'Produto de inventário excluído',
  DeleteInventoryProductError = 'Erro ao excluir produto de inventário',
  RetrieveInventoryProductsInit = 'Obtendo produtos de inventário',
  RetrieveInventoryProductsSuccess = 'Produtos de inventário obtidos',
  RetrieveInventoryProductsError = 'Erro ao obter produtos de inventário',
  RetrieveInventoryProductsEmpty = 'Nenhum produto de inventário encontrado',
  RetrieveInventoryProductSchemaInit = 'Obtendo esquema de produtos de inventário',
  RetrieveInventoryProductSchemaSuccess = 'Esquema de produtos de inventário obtido',
  RetrieveInventoryProductSchemaError = 'Erro ao obter esquema de produtos de inventário',
  SubmitInventoryProductForReviewInit = 'Enviando produto de inventário para revisão',
  SubmitInventoryProductForReviewSuccess = 'Produto de inventário enviado para revisão',
  SubmitInventoryProductForReviewError = 'Erro ao enviar produto de inventário para revisão',
  ApproveInventoryProductInit = 'Aprovando produto de inventário',
  ApproveInventoryProductSuccess = 'Produto de inventário aprovado',
  ApproveInventoryProductError = 'Erro ao aprovar produto de inventário',
  RejectInventoryProductInit = 'Rejeitando produto de inventário',
  RejectInventoryProductSuccess = 'Produto de inventário rejeitado',
  RejectInventoryProductError = 'Erro ao rejeitar produto de inventário',
  PutInventoryProductOnHoldInit = 'Colocando produto de inventário em espera',
  PutInventoryProductOnHoldSuccess = 'Produto de inventário colocado em espera',
  PutInventoryProductOnHoldError = 'Erro ao colocar produto de inventário em espera',
  RetireInventoryProductInit = 'Descontinuando produto de inventário',
  RetireInventoryProductSuccess = 'Produto de inventário descontinuado',
  RetireInventoryProductError = 'Erro ao descontinuar produto de inventário',
  SuspendInventoryProductInit = 'Suspendendo produto de inventário',
  SuspendInventoryProductSuccess = 'Produto de inventário suspenso',
  SuspendInventoryProductError = 'Erro ao suspender produto de inventário',
  BanInventoryProductInit = 'Banindo produto de inventário',
  BanInventoryProductSuccess = 'Produto de inventário banido',
  BanInventoryProductError = 'Erro ao banir produto de inventário',
  ReactivateInventoryProductInit = 'Reativando produto de inventário',
  ReactivateInventoryProductSuccess = 'Produto de inventário reativado',
  ReactivateInventoryProductError = 'Erro ao reativar produto de inventário',

  // Inventory product logs
  RetrieveInventoryProductLogsInit = 'Obtendo registros de produto de inventário',
  RetrieveInventoryProductLogsSuccess = 'Registros de produto de inventário obtido',
  RetrieveInventoryProductLogsError = 'Erro ao obter registros de produto de inventário',
  RetrieveInventoryProductLogsEmpty = 'Nenhum registro de produto de inventário encontrado',
  RetrieveInventoryProductLogSchemaInit = 'Obtendo esquema de registros de produtos de inventário',
  RetrieveInventoryProductLogSchemaSuccess = 'Esquema de registros de produtos de inventário obtido',
  RetrieveInventoryProductLogSchemaError = 'Erro ao obter esquema de registros de produtos de inventário',

  // Inventory product pricing history
  RetrieveInventoryProductPricingHistoryInit = 'Obtendo histórico de preços de produto de inventário',
  RetrieveInventoryProductPricingHistorySuccess = 'Histórico de preços de produto de inventário obtido',
  RetrieveInventoryProductPricingHistoryError = 'Erro ao obter histórico de preços de produto de inventário',
  RetrieveInventoryProductPricingHistoryEmpty = 'Nenhum histórico de preços de produto de inventário encontrado',
  RetrieveInventoryProductPricingHistorySchemaInit = 'Obtendo esquema de histórico de preços de produtos de inventário',
  RetrieveInventoryProductPricingHistorySchemaSuccess = 'Esquema de histórico de preços de produtos de inventário obtido',
  RetrieveInventoryProductPricingHistorySchemaError = 'Erro ao obter esquema de histórico de preços de produtos de inventário',

  // Inventory items
  CreateInventoryItemInit = 'Criando item de inventário',
  CreateInventoryItemSuccess = 'Item de inventário criado',
  CreateInventoryItemError = 'Erro ao criar item de inventário',
  RetrieveInventoryItemInit = 'Obtendo item de inventário',
  RetrieveInventoryItemSuccess = 'Item de inventário obtido',
  RetrieveInventoryItemError = 'Erro ao obter item de inventário',
  UpdateInventoryItemInit = 'Atualizando item de inventário',
  UpdateInventoryItemSuccess = 'Item de inventário atualizado',
  UpdateInventoryItemError = 'Erro ao atualizar item de inventário',
  DeleteInventoryItemInit = 'Excluindo item de inventário',
  DeleteInventoryItemSuccess = 'Item de inventário excluído',
  DeleteInventoryItemError = 'Erro ao excluir item de inventário',
  RetrieveInventoryItemsInit = 'Obtendo itens de inventário',
  RetrieveInventoryItemsSuccess = 'Itens de inventário obtidos',
  RetrieveInventoryItemsError = 'Erro ao obter itens de inventário',
  RetrieveInventoryItemsEmpty = 'Nenhum item de inventário encontrado',
  RetrieveInventoryItemSchemaInit = 'Obtendo esquema de itens de inventário',
  RetrieveInventoryItemSchemaSuccess = 'Esquema de itens de inventário obtido',
  RetrieveInventoryItemSchemaError = 'Erro ao obter esquema de itens de inventário',

  // Batches
  CreateBatchInit = 'Criando lote',
  CreateBatchSuccess = 'Lote criado',
  CreateBatchError = 'Erro ao criar lote',
  RetrieveBatchInit = 'Obtendo lote',
  RetrieveBatchSuccess = 'Lote obtido',
  RetrieveBatchError = 'Erro ao obter lote',
  UpdateBatchInit = 'Atualizando lote',
  UpdateBatchSuccess = 'Lote atualizado',
  UpdateBatchError = 'Erro ao atualizar lote',
  DeleteBatchInit = 'Excluindo lote',
  DeleteBatchSuccess = 'Lote excluído',
  DeleteBatchError = 'Erro ao excluir lote',
  RetrieveBatchesInit = 'Obtendo lotes',
  RetrieveBatchesSuccess = 'Lotes obtidos',
  RetrieveBatchesError = 'Erro ao obter lotes',
  RetrieveBatchesEmpty = 'Nenhum lote encontrado',
  RetrieveBatchSchemaInit = 'Obtendo esquema de lotes',
  RetrieveBatchSchemaSuccess = 'Esquema de lotes obtido',
  RetrieveBatchSchemaError = 'Erro ao obter esquema de lotes',

  // Inventory receivements
  CreateInventoryReceivementInit = 'Criando recebimento de itens',
  CreateInventoryReceivementSuccess = 'Recebimento de itens criado',
  CreateInventoryReceivementError = 'Erro ao criar recebimento de itens',
  RetrieveInventoryReceivementInit = 'Obtendo recebimento de itens',
  RetrieveInventoryReceivementSuccess = 'Recebimento de itens obtido',
  RetrieveInventoryReceivementError = 'Erro ao obter recebimento de itens',
  UpdateInventoryReceivementInit = 'Atualizando recebimento de itens',
  UpdateInventoryReceivementSuccess = 'Recebimento de itens atualizado',
  UpdateInventoryReceivementError = 'Erro ao atualizar recebimento de itens',
  DeleteInventoryReceivementInit = 'Excluindo recebimento de itens',
  DeleteInventoryReceivementSuccess = 'Recebimento de itens excluído',
  DeleteInventoryReceivementError = 'Erro ao excluir recebimento de itens',
  RetrieveInventoryReceivementsInit = 'Obtendo recebimentos de itens',
  RetrieveInventoryReceivementsSuccess = 'Recebimentos de itens obtidos',
  RetrieveInventoryReceivementsError = 'Erro ao obter recebimentos de itens',
  RetrieveInventoryReceivementsEmpty = 'Nenhum recebimento de itens encontrado',
  RetrieveInventoryReceivementSchemaInit = 'Obtendo esquema de recebimentos de itens',
  RetrieveInventoryReceivementSchemaSuccess = 'Esquema de recebimentos de itens obtido',
  RetrieveInventoryReceivementSchemaError = 'Erro ao obter esquema de recebimentos de itens',
  RegisterInventoryReceivementInit = 'Registrando recebimento de itens',
  RegisterInventoryReceivementSuccess = 'Recebimento de itens registrado',
  RegisterInventoryReceivementError = 'Erro ao registrar recebimento de itens',
  CancelInventoryReceivementInit = 'Cancelando recebimento de itens',
  CancelInventoryReceivementSuccess = 'Recebimento de itens cancelado',
  CancelInventoryReceivementError = 'Erro ao cancelar recebimento de itens',

  // Inventory receivement logs
  RetrieveInventoryReceivementLogsInit = 'Obtendo registros de recebimento de itens',
  RetrieveInventoryReceivementLogsSuccess = 'Registros de recebimento de itens obtido',
  RetrieveInventoryReceivementLogsError = 'Erro ao obter registros de recebimento de itens',
  RetrieveInventoryReceivementLogsEmpty = 'Nenhum registro de recebimento de itens encontrado',
  RetrieveInventoryReceivementLogSchemaInit = 'Obtendo esquema de registros de recebimentos de itens',
  RetrieveInventoryReceivementLogSchemaSuccess = 'Esquema de registros de recebimentos de itens obtido',
  RetrieveInventoryReceivementLogSchemaError = 'Erro ao obter esquema de registros de recebimentos de itens',

  // Inventory movements
  CreateInventoryMovementInit = 'Criando movimentação de inventário',
  CreateInventoryMovementSuccess = 'Movimentação de inventário criada',
  CreateInventoryMovementError = 'Erro ao criar movimentação de inventário',
  RetrieveInventoryMovementInit = 'Obtendo movimentação de inventário',
  RetrieveInventoryMovementSuccess = 'Movimentação de inventário obtida',
  RetrieveInventoryMovementError = 'Erro ao obter movimentação de inventário',
  UpdateInventoryMovementInit = 'Atualizando movimentação de inventário',
  UpdateInventoryMovementSuccess = 'Movimentação de inventário atualizada',
  UpdateInventoryMovementError = 'Erro ao atualizar movimentação de inventário',
  DeleteInventoryMovementInit = 'Excluindo movimentação de inventário',
  DeleteInventoryMovementSuccess = 'Movimentação de inventário excluída',
  DeleteInventoryMovementError = 'Erro ao excluir movimentação de inventário',
  RetrieveInventoryMovementsInit = 'Obtendo movimentações de inventário',
  RetrieveInventoryMovementsSuccess = 'Movimentações de inventário obtidas',
  RetrieveInventoryMovementsError = 'Erro ao obter movimentações de inventário',
  RetrieveInventoryMovementsEmpty = 'Nenhuma movimentação de inventário encontrada',
  RetrieveInventoryMovementSchemaInit = 'Obtendo esquema de movimentações de inventário',
  RetrieveInventoryMovementSchemaSuccess = 'Esquema de movimentações de inventário obtido',
  RetrieveInventoryMovementSchemaError = 'Erro ao obter esquema de movimentações de inventário',

  // Basket
  RetrieveBasketInit = 'Obtendo carrinho de compras',
  RetrieveBasketSuccess = 'Carrinho de compras obtido',
  RetrieveBasketError = 'Erro ao obter carrinho de compras',
  RetrieveBasketSchemaInit = 'Obtendo esquema de carrinho de compras',
  RetrieveBasketSchemaSuccess = 'Esquema de carrinho de compras obtido',
  RetrieveBasketSchemaError = 'Erro ao obter esquema de carrinho de compras',

  // Basket items
  CreateBasketItemInit = 'Adicionando item ao carrinho',
  CreateBasketItemSuccess = 'Item adicionado ao carrinho',
  CreateBasketItemError = 'Erro ao adicionar item ao carrinho',
  RetrieveBasketItemInit = 'Obtendo item do carrinho',
  RetrieveBasketItemSuccess = 'Item do carrinho obtido',
  RetrieveBasketItemError = 'Erro ao obter item do carrinho',
  UpdateBasketItemInit = 'Atualizando item do carrinho',
  UpdateBasketItemSuccess = 'Item do carrinho atualizado',
  UpdateBasketItemError = 'Erro ao atualizar item do carrinho',
  DeleteBasketItemInit = 'Excluindo item do carrinho',
  DeleteBasketItemSuccess = 'Item do carrinho excluído',
  DeleteBasketItemError = 'Erro ao excluir item do carrinho',
  RetrieveBasketItemsInit = 'Obtendo itens do carrinho',
  RetrieveBasketItemsSuccess = 'Itens do carrinho obtidos',
  RetrieveBasketItemsError = 'Erro ao obter itens do carrinho',
  RetrieveBasketItemsEmpty = 'Nenhum item de carrinho encontrado',
  RetrieveBasketItemSchemaInit = 'Obtendo esquema de itens de carrinho',
  RetrieveBasketItemSchemaSuccess = 'Esquema de itens de carrinho obtido',
  RetrieveBasketItemSchemaError = 'Erro ao obter esquema de itens de carrinho',

  // Checkout
  CheckoutInit = 'Iniciando checkout',
  CheckoutSuccess = 'Checkout concluído',
  CheckoutError = 'Erro ao realizar checkout',
  PreviewCheckoutInit = 'Iniciando pré-visualização de checkout',
  PreviewCheckoutSuccess = 'Pré-visualização de checkout obtida',
  PreviewCheckoutError = 'Erro ao realizar pré-visualização de checkout',
  RetrieveCheckoutSchemaInit = 'Obtendo esquema de checkout',
  RetrieveCheckoutSchemaSuccess = 'Esquema de checkout obtido',
  RetrieveCheckoutSchemaError = 'Erro ao obter esquema de checkout',

  // Payments
  CreatePaymentInit = 'Criando pagamento',
  CreatePaymentSuccess = 'Pagamento criado',
  CreatePaymentError = 'Erro ao criar pagamento',
  RetrievePaymentInit = 'Obtendo pagamento',
  RetrievePaymentSuccess = 'Pagamento obtido',
  RetrievePaymentError = 'Erro ao obter pagamento',
  UpdatePaymentInit = 'Atualizando pagamento',
  UpdatePaymentSuccess = 'Pagamento atualizado',
  UpdatePaymentError = 'Erro ao atualizar pagamento',
  DeletePaymentInit = 'Excluindo pagamento',
  DeletePaymentSuccess = 'Pagamento excluído',
  DeletePaymentError = 'Erro ao excluir pagamento',
  RetrievePaymentsInit = 'Obtendo pagamentos',
  RetrievePaymentsSuccess = 'Pagamentos obtidos',
  RetrievePaymentsError = 'Erro ao obter pagamentos',
  RetrievePaymentsEmpty = 'Nenhum pagamento encontrado',
  CancelPaymentInit = 'Cancelando pagamento',
  CancelPaymentSuccess = 'Pagamento cancelado',
  CancelPaymentError = 'Erro ao cancelar pagamento',
  RetrievePaymentSchemaInit = 'Obtendo esquema de pagamentos',
  RetrievePaymentSchemaSuccess = 'Esquema de pagamentos obtido',
  RetrievePaymentSchemaError = 'Erro ao obter esquema de pagamentos',

  // Payment logs
  RetrievePaymentLogsInit = 'Obtendo registros de pgamento',
  RetrievePaymentLogsSuccess = 'Registros de pagamento obtido',
  RetrievePaymentLogsError = 'Erro ao obter registros de pagamento',
  RetrievePaymentLogsEmpty = 'Nenhum registro de pagamento encontrado',
  RetrievePaymentLogSchemaInit = 'Obtendo esquema de registros de pagamentos',
  RetrievePaymentLogSchemaSuccess = 'Esquema de registros de pagamentos obtido',
  RetrievePaymentLogSchemaError = 'Erro ao obter esquema de registros de pagamentos',

  // Payments gateways
  CreatePaymentGatewayInit = 'Criando gateway de pagamentos',
  CreatePaymentGatewaySuccess = 'Gateway de pagamentos criado',
  CreatePaymentGatewayError = 'Erro ao criar gateway de pagamentos',
  RetrievePaymentGatewayInit = 'Obtendo gateway de pagamentos',
  RetrievePaymentGatewaySuccess = 'Gateway de pagamentos obtido',
  RetrievePaymentGatewayError = 'Erro ao obter gateway de pagamentos',
  UpdatePaymentGatewayInit = 'Atualizando gateway de pagamentos',
  UpdatePaymentGatewaySuccess = 'Gateway de pagamentos atualizado',
  UpdatePaymentGatewayError = 'Erro ao atualizar gateway de pagamentos',
  DeletePaymentGatewayInit = 'Excluindo gateway de pagamentos',
  DeletePaymentGatewaySuccess = 'Gateway de pagamentos excluído',
  DeletePaymentGatewayError = 'Erro ao excluir gateway de pagamentos',
  RetrievePaymentGatewaysInit = 'Obtendo gateway de pagamentos',
  RetrievePaymentGatewaysSuccess = 'Gateway de pagamentos obtidos',
  RetrievePaymentGatewaysError = 'Erro ao obter gateway de pagamentos',
  RetrievePaymentGatewaysEmpty = 'Nenhum gateway de pagamentos encontrado',
  RetrievePaymentGatewaySchemaInit = 'Obtendo esquema de gateway de pagamentos',
  RetrievePaymentGatewaySchemaSuccess = 'Esquema de gateway de pagamentos obtido',
  RetrievePaymentGatewaySchemaError = 'Erro ao obter esquema de gateway de pagamentos',
  ChangePaymentGatewayCredentialsInit = 'Alterando credenciais de gateway de pagamentos',
  ChangePaymentGatewayCredentialsSuccess = 'Credenciais de gateway de pagamentos alteradas',
  ChangePaymentGatewayCredentialsError = 'Erro ao alterar credenciais de gateway de pagamentos',
  ActivatePaymentGatewayInit = 'Ativando gateway de pagamentos',
  ActivatePaymentGatewaySuccess = 'Gateway de pagamentos ativado',
  ActivatePaymentGatewayError = 'Erro ao ativar gateway de pagamentos',
  PausePaymentGatewayInit = 'Pausando gateway de pagamentos',
  PausePaymentGatewaySuccess = 'Gateway de pagamentos pausado',
  PausePaymentGatewayError = 'Erro ao pausar gateway de pagamentos',
  RetirePaymentGatewayInit = 'Descontinuando gateway de pagamentos',
  RetirePaymentGatewaySuccess = 'Gateway de pagamentos descontinuado',
  RetirePaymentGatewayError = 'Erro ao descontinuar gateway de pagamentos',
  ReactivatePaymentGatewayInit = 'Reativando gateway de pagamentos',
  ReactivatePaymentGatewaySuccess = 'Gateway de pagamentos reativado',
  ReactivatePaymentGatewayError = 'Erro ao reativar gateway de pagamentos',

  // Payment gateway logs
  RetrievePaymentGatewayLogsInit = 'Obtendo registros de gateway de pagamentos',
  RetrievePaymentGatewayLogsSuccess = 'Registros de gateway de pagamento obtido',
  RetrievePaymentGatewayLogsError = 'Erro ao obter registros de gateway de pagamentos',
  RetrievePaymentGatewayLogsEmpty = 'Nenhum registro de gateway de pagamento encontrado',
  RetrievePaymentGatewayLogSchemaInit = 'Obtendo esquema de registros de gateways de pagamentos',
  RetrievePaymentGatewayLogSchemaSuccess = 'Esquema de registros de gateways de pagamentos obtido',
  RetrievePaymentGatewayLogSchemaError = 'Erro ao obter esquema de registros de gateways de pagamentos',

  // Payment methods
  RetrievePaymentMethodsInit = 'Obtendo métodos de pagamentos',
  RetrievePaymentMethodsSuccess = 'Métodos de pagamentos obtidos',
  RetrievePaymentMethodsError = 'Erro ao obter métodos de pagamentos',
  RetrievePaymentMethodsEmpty = 'Nenhum método de pagamentos encontrado',
  RetrievePaymentMethodSchemaInit = 'Obtendo esquema de métodos de pagamentos',
  RetrievePaymentMethodSchemaSuccess = 'Esquema de métodos de pagamentos obtido',
  RetrievePaymentMethodSchemaError = 'Erro ao obter esquema de métodos de pagamentos',

  // Banks
  CreateBankInit = 'Criando banco',
  CreateBankSuccess = 'Banco criado',
  CreateBankError = 'Erro ao criar banco',
  RetrieveBankInit = 'Obtendo banco',
  RetrieveBankSuccess = 'Banco obtido',
  RetrieveBankError = 'Erro ao obter banco',
  UpdateBankInit = 'Atualizando banco',
  UpdateBankSuccess = 'Banco atualizado',
  UpdateBankError = 'Erro ao atualizar banco',
  DeleteBankInit = 'Excluindo banco',
  DeleteBankSuccess = 'Banco excluído',
  DeleteBankError = 'Erro ao excluir banco',
  RetrieveBanksInit = 'Obtendo bancos',
  RetrieveBanksSuccess = 'Bancos obtidos',
  RetrieveBanksError = 'Erro ao obter bancos',
  RetrieveBanksEmpty = 'Nenhum banco encontrado',
  RetrieveBankSchemaInit = 'Obtendo esquema de bancos',
  RetrieveBankSchemaSuccess = 'Esquema de bancos obtido',
  RetrieveBankSchemaError = 'Erro ao obter esquema de bancos',
  CreateBanksInit = 'Criando bancos',
  CreateBanksSuccess = 'Bancos criados',
  CreateBanksError = 'Erro ao criar bancos',
  UpdateBanksInit = 'Atualizando bancos',
  UpdateBanksSuccess = 'Bancos atualizados',
  UpdateBanksError = 'Erro ao atualizar bancos',
  PutBankOnHoldInit = 'Colocando banco em espera',
  PutBankOnHoldSuccess = 'Banco colocado em espera',
  PutBankOnHoldError = 'Erro ao colocar banco em espera',
  CloseBankInit = 'Fechando banco',
  CloseBankSuccess = 'Banco fechado',
  CloseBankError = 'Erro ao fechar banco',
  ReactivateBankInit = 'Reativando banco',
  ReactivateBankSuccess = 'Banco reativado',
  ReactivateBankError = 'Erro ao reativar banco',

  // Bank logs
  RetrieveBankLogsInit = 'Obtendo registros de banco',
  RetrieveBankLogsSuccess = 'Registros de banco obtido',
  RetrieveBankLogsError = 'Erro ao obter registros de banco',
  RetrieveBankLogsEmpty = 'Nenhum registro de banco encontrado',
  RetrieveBankLogSchemaInit = 'Obtendo esquema de registros de bancos',
  RetrieveBankLogSchemaSuccess = 'Esquema de registros de bancos obtido',
  RetrieveBankLogSchemaError = 'Erro ao obter esquema de registros de bancos',

  // Transfers
  CreateTransferInit = 'Criando transferência',
  CreateTransferSuccess = 'Transferência criada',
  CreateTransferError = 'Erro ao criar transferência',
  RetrieveTransferInit = 'Obtendo transferência',
  RetrieveTransferSuccess = 'Transferência obtida',
  RetrieveTransferError = 'Erro ao obter transferência',
  UpdateTransferInit = 'Atualizando transferência',
  UpdateTransferSuccess = 'Transferência atualizada',
  UpdateTransferError = 'Erro ao atualizar transferência',
  DeleteTransferInit = 'Excluindo transferência',
  DeleteTransferSuccess = 'Transferência excluída',
  DeleteTransferError = 'Erro ao excluir transferência',
  RetrieveTransfersInit = 'Obtendo transferências',
  RetrieveTransfersSuccess = 'Transferências obtidas',
  RetrieveTransfersError = 'Erro ao obter transferências',
  RetrieveTransfersEmpty = 'Nenhuma transferência encontrada',
  RetrieveTransferSchemaInit = 'Obtendo esquema de transferências',
  RetrieveTransferSchemaSuccess = 'Esquema de transferências obtido',
  RetrieveTransferSchemaError = 'Erro ao obter esquema de transferências',

  // Transfer logs
  RetrieveTransferLogsInit = 'Obtendo registros de transferência',
  RetrieveTransferLogsSuccess = 'Registros de transferência obtido',
  RetrieveTransferLogsError = 'Erro ao obter registros de transferência',
  RetrieveTransferLogsEmpty = 'Nenhum registro de transferência encontrado',
  RetrieveTransferLogSchemaInit = 'Obtendo esquema de registros de transferências',
  RetrieveTransferLogSchemaSuccess = 'Esquema de registros de transferências obtido',
  RetrieveTransferLogSchemaError = 'Erro ao obter esquema de registros de transferências',

  // Deliveries
  CreateDeliveryInit = 'Criando entrega',
  CreateDeliverySuccess = 'Entrega criada',
  CreateDeliveryError = 'Erro ao criar entrega',
  RetrieveDeliveryInit = 'Obtendo entrega',
  RetrieveDeliverySuccess = 'Entrega obtida',
  RetrieveDeliveryError = 'Erro ao obter entrega',
  UpdateDeliveryInit = 'Atualizando entrega',
  UpdateDeliverySuccess = 'Entrega atualizada',
  UpdateDeliveryError = 'Erro ao atualizar entrega',
  DeleteDeliveryInit = 'Excluindo entrega',
  DeleteDeliverySuccess = 'Entrega excluída',
  DeleteDeliveryError = 'Erro ao excluir entrega',
  RetrieveDeliveriesInit = 'Obtendo entregas',
  RetrieveDeliveriesSuccess = 'Entregas obtidas',
  RetrieveDeliveriesError = 'Erro ao obter entregas',
  RetrieveDeliveriesEmpty = 'Nenhuma entrega encontrada',
  RetrieveDeliverySchemaInit = 'Obtendo esquema de entregas',
  RetrieveDeliverySchemaSuccess = 'Esquema de entregas obtido',
  RetrieveDeliverySchemaError = 'Erro ao obter esquema de entregas',
  CancelDeliveryInit = 'Cancelando entrega',
  CancelDeliverySuccess = 'Entrega cancelada',
  CancelDeliveryError = 'Erro ao cancelar entrega',
  ConfirmDeliveryInit = 'Confirmando entrega',
  ConfirmDeliverySuccess = 'Entrega confirmada',
  ConfirmDeliveryError = 'Erro ao confirmar entrega',
  MarkDeliveryAsPendingInit = 'Marcando entrega como pendente',
  MarkDeliveryAsPendingSuccess = 'Entrega marcada como pendente',
  MarkDeliveryAsPendingError = 'Erro ao marcar entrega como pendente',
  MarkDeliveryAsInTransitInit = 'Marcando entrega como em trânsito',
  MarkDeliveryAsInTransitSuccess = 'Entrega marcada como em trânsito',
  MarkDeliveryAsInTransitError = 'Erro ao marcar entrega como em trânsito',
  MarkDeliveryAsOutForDeliveryInit = 'Marcando entrega como fora para entrega',
  MarkDeliveryAsOutForDeliverySuccess = 'Entrega marcada como fora para entrega',
  MarkDeliveryAsOutForDeliveryError = 'Erro ao marcar entrega como fora para entrega',
  MarkDeliveryAsFailedInit = 'Marcando entrega como mal sucedida',
  MarkDeliveryAsFailedSuccess = 'Entrega marcada como mal sucedida',
  MarkDeliveryAsFailedError = 'Erro ao marcar entrega como mal sucedida',
  MarkDeliveryAsLostInit = 'Marcando entrega como perdida',
  MarkDeliveryAsLostSuccess = 'Entrega marcada como perdida',
  MarkDeliveryAsLostError = 'Erro ao marcar entrega como perdida',

  // Delivery logs
  RetrieveDeliveryLogsInit = 'Obtendo registros de entrega',
  RetrieveDeliveryLogsSuccess = 'Registros de entrega obtido',
  RetrieveDeliveryLogsError = 'Erro ao obter registros de entrega',
  RetrieveDeliveryLogsEmpty = 'Nenhum registro de entrega encontrado',
  RetrieveDeliveryLogSchemaInit = 'Obtendo esquema de registros de entregas',
  RetrieveDeliveryLogSchemaSuccess = 'Esquema de registros de entregas obtido',
  RetrieveDeliveryLogSchemaError = 'Erro ao obter esquema de registros de entregas',

  // Delivery places
  CreateDeliveryPlaceInit = 'Criando local de entrega',
  CreateDeliveryPlaceSuccess = 'Local de entrega criado',
  CreateDeliveryPlaceError = 'Erro ao criar local de entrega',
  RetrieveDeliveryPlaceInit = 'Obtendo local de entrega',
  RetrieveDeliveryPlaceSuccess = 'Local de entrega obtido',
  RetrieveDeliveryPlaceError = 'Erro ao obter local de entrega',
  UpdateDeliveryPlaceInit = 'Atualizando local de entrega',
  UpdateDeliveryPlaceSuccess = 'Local de entrega atualizado',
  UpdateDeliveryPlaceError = 'Erro ao atualizar local de entrega',
  DeleteDeliveryPlaceInit = 'Excluindo local de entrega',
  DeleteDeliveryPlaceSuccess = 'Local de entrega excluído',
  DeleteDeliveryPlaceError = 'Erro ao excluir local de entrega',
  RetrieveDeliveryPlacesInit = 'Obtendo locais de entrega',
  RetrieveDeliveryPlacesSuccess = 'Locais de entrega obtidos',
  RetrieveDeliveryPlacesError = 'Erro ao obter locais de entrega',
  RetrieveDeliveryPlacesEmpty = 'Nenhum local de entrega encontrado',
  ConfirmDeleteDeliveryPlace = 'Você tem certeza que deseja excluir este local de entrega?',
  RetrieveDeliveryPlaceSchemaInit = 'Obtendo esquema de locais de entrega',
  RetrieveDeliveryPlaceSchemaSuccess = 'Esquema de locais de entrega obtido',
  RetrieveDeliveryPlaceSchemaError = 'Erro ao obter esquema de locais de entrega',

  // Addresses
  RetrieveAddressInit = 'Obtendo endereço',
  RetrieveAddressSuccess = 'Endereço obtido',
  RetrieveAddressError = 'Erro ao obter endereço',
  RetrieveAddressEmpty = 'Nenhum endereço encontrado',
  RetrieveAddressSchemaInit = 'Obtendo esquema de endereços',
  RetrieveAddressSchemaSuccess = 'Esquema de endereços obtido',
  RetrieveAddressSchemaError = 'Erro ao obter esquema de endereços',

  // Locations
  RetrieveLocationInit = 'Obtendo localização',
  RetrieveLocationSuccess = 'Localização obtida',
  RetrieveLocationError = 'Erro ao obter localização',
  RetrieveLocationEmpty = 'Nenhuma localização encontrada',
  RetrieveLocationSchemaInit = 'Obtendo esquema de localizações',
  RetrieveLocationSchemaSuccess = 'Esquema de localizações obtido',
  RetrieveLocationSchemaError = 'Erro ao obter esquema de localizações',

  // Other (old)
  PasswordUpdatedSuccess = 'Senha atualizada com sucesso',
  ErrorChanginPassword = 'Erro ao atualizar senha',
  BlankPassword = 'Senha não pode ser em branco',
  DigitPassword = 'Digite sua nova senha',
  DiferentPassword = 'Senha e Confirmar senha são diferentes',
  InvalidToken = 'Token inválido',
  UserNotFound = 'Usuário não encontrado',
  DigitCode = 'Digite o código que você recebeu',
  ErrorRetrieve = 'Erro ao buscar dados',
  ErrorSave = 'Ocorreu um erro ao salvar',
  ErrorMove = 'Erro ao mover',
  SuccessActivate = 'Ativado com sucesso',
  ErrorActivate = 'Erro ao ativar',
  SuccessPause = 'Pausado com sucesso',
  ErrorPause = 'Erro ao pausar',
  SuccessRetire = 'Descontinuado com sucesso',
  ErrorRetire = 'Erro ao descontinuar',
  SuccessSave = 'Salvo com sucesso',
  ErrorUpdate = 'Ocorreu um erro ao atualizar',
  SuccessUpdate = 'Atualizado com sucesso',
  SuccessSync = 'Dados sincronizados com aplicativo',
  ErrorSync = 'Erro ao sincronizar dados com aplicativo',
  Validate = 'Preencha todos os campos',
  ValidateRequired = 'Preencha os campos obrigatórios',
  WarningDelete = 'Você deseja realmente remover este registro?',
  WarningCancelSending = 'Você deseja realmente cancelar o envio de lote?',
  WarningCancelAuthorization = 'Você deseja realmente cancelar esta autorização?',
  WarningRegister = 'Você deseja realmente registrar esta estampagem?',
  WarningInform = 'Você deseja realmente informar esta estampagem?',
  SuccessCancelAuthorization = 'Autorização cancelada com sucesso',
  ErrorCancelAuthorization = 'Erro ao cancelar autorização',
  SuccessDelete = 'Registro removido com sucesso',
  ErrorDelete = 'Erro ao remover registro',
  InvalidField = 'Campo inválido',
  SuccessInit = 'Iniciado com sucesso',
  ErrorCancel = 'Erro ao cancelar',
  SuccessCancel = 'Cancelado com sucesso',
  ErrorInit = 'Erro ao iniciar',
  SuccessRegister = 'Registrado com sucesso',
  ErrorRegister = 'Erro ao registrar',
  IncludeVehicle = 'Deseja incluir um veículo?',
  IncludeContract = 'Deseja incluir um contrato?',
  FromGreaterThanTo = "Valor 'De' não pode ser maior que 'Até'",
  ToLessThanFrom = "Valor 'Até' não pode ser menor que 'De'",
  ToGreaterThanNextFrom = "Valor 'De' não pode ser menor e nem igual ao 'Até' anterior",
  Duplicates = 'Valores duplicados',
  PaymentError = 'Erro ao gerar pagamento',
  BilletNotFound = 'Pagamento não encontrado',
  UpdateErrorPermission = 'Você não possui permissão para editar',
  CreateErrorPermission = 'Você não possui permissão para criar',
  RemoveErrorPermission = 'Você não possui permissão para apagar',
  ViewErrorPermission = 'Você não possui permissão para visualizar',
  SuccessInform = 'Informado com sucesso',
  ErrorInform = 'Erro ao informar',
  ErrorSendSms = 'Erro ao enviar SMS',
  SuccessSms = 'SMS enviado com sucesso',
  SuccessEmail = 'E-mail enviado com sucesso',
  ErrorSendEmail = 'Erro ao enviar e-mail',
  SpecieWarning = 'Selecione uma espécie',
  SuccessSent = 'Enviado com sucesso',
  ErrorSend = 'Erro ao enviar',
  NegativaMessage = 'Você tem certeza que deseja Negativar este evento?',
  ApproveMessage = 'Você tem certeza que deseja Aprovar este evento?',
  ImageRequired = 'Imagem é obrigatória',
  ExistingColor = 'Essa cor já existe',
  LoginError = 'Erro ao efetuar login',
  InvalidPassword = 'Senha inválida, digite ao menos 6 caracteres',
  LotesBoughtSuccess = 'Lotes adquiridos com sucesso',
  LotesBoughtError = 'Erro ao efetuar a compra',
  ConvertBlankError = 'Erro ao converter blank',
  Error = 'Ocorreu um erro',
  CanLog = 'Seu usuário foi criado com sucesso, agora você pode fazer a autenticação no sistema.',
  EtiquetaImageSize = 'A imagem deve ser no máximo 200x200',
  CannotIncludeAuth = 'Esta autorização não pode ser inclusa no sistema',
  ReadFileError = 'Erro na leitura do arquivo',
}
