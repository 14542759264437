import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from 'src/app/shared/interfaces/company.interface';
import { ApiSettings } from 'src/app/shared/interfaces/settings.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { TokenService } from 'src/app/shared/services/auth/token.service';
import { PaginatedResponse } from '../../interfaces/core.interface';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  settings: ApiSettings;

  readonly user$: Observable<User>;
  readonly company$: Observable<Company>;

  private userSubject: BehaviorSubject<User>;
  private companySubject: BehaviorSubject<Company>;

  constructor(private http: HttpClient, private tokenService: TokenService) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.user$ = this.userSubject.asObservable();

    this.companySubject = new BehaviorSubject<Company>(null);
    this.company$ = this.companySubject.asObservable();
  }

  get loggedUser(): User {
    return this.userSubject.getValue();
  }

  get company(): Company {
    return this.companySubject.getValue();
  }

  setUser(user: User): void {
    const userId = user?.id;

    if (userId == null) {
      this.userSubject.next(null);
    } else {
      this.userSubject.next(user);
    }
  }

  setCompany(company: Company): void {
    const companyId = company?.id;

    if (companyId == null) {
      localStorage.removeItem('settings.companyId');
      this.companySubject.next(null);
    } else {
      localStorage.setItem('settings.companyId', company.id.toString());
      this.companySubject.next(company);
    }
  }

  async getLoggedUser(): Promise<User> {
    return this.http
      .get<User>(this.apiUrl().concat('api/v1/users/logged_user/'))
      .toPromise();
  }

  async getSelectedCompany(): Promise<Company> {
    const companyId = localStorage.getItem('settings.companyId');
    if (companyId == null || companyId === '') {
      return null;
    }

    return this.http
      .get<Company>(
        this.apiUrl().concat(`api/v1/enterprise/companies/${companyId}/`)
      )
      .toPromise();
  }

  async getFirstCompanyAvailable(): Promise<Company> {
    return this.http
      .get<PaginatedResponse<Company>>(
        this.apiUrl().concat(`api/v1/enterprise/companies/`)
      )
      .pipe(map((val) => val.results[0]))
      .toPromise();
  }

  async initApp(): Promise<void> {
    const apiSettings = await this.http
      .get<ApiSettings>('assets/settings.json')
      .toPromise();

    this.settings = apiSettings;

    if (this.tokenService.isLoggedIn()) {
      try {
        const user = await this.getLoggedUser();
        this.setUser(user);
      } catch {
        this.tokenService.logOut();
        return null;
      }

      try {
        const company = await this.getSelectedCompany();
        this.setCompany(company);
      } catch {
        try {
          const company = await this.getFirstCompanyAvailable();
          this.setCompany(company);
        } catch {
          this.setCompany(null);
        }
      }
    }
  }

  apiUrl() {
    return this.settings.apiUrl;
  }
}
