import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { AppointmentNested } from 'src/app/shared/interfaces/agenda.interface';
import { requiredTogether } from 'src/app/shared/validators/form-group.validator';

@Component({
  selector: 'app-appointment-input',
  templateUrl: './appointment-input.component.html',
  styleUrls: ['./appointment-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppointmentInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AppointmentInputComponent),
      multi: true,
    },
  ],
})
export class AppointmentInputComponent implements ControlValueAccessor {
  @Input() isLarge = false;
  @Input() showError = false;
  @Input() required = false;

  @Input()
  set disabled(isDisabled) {
    this.setDisabledState(isDisabled);
  }
  get disabled(): boolean {
    return this.form.disabled;
  }

  form: FormGroup = null;

  constructor() {
    this.form = new FormGroup(
      {
        starts_at: new FormControl(null),
        ends_at: new FormControl(null),
        assigned_to: new FormControl(null),
      },
      requiredTogether(Validators.required, [
        'starts_at',
        'ends_at',
        'assigned_to',
      ])
    );
  }

  get isEmpty(): boolean {
    const startsAt = this.form.get('starts_at').value;
    const endsAt = this.form.get('ends_at').value;
    const assignedTo = this.form.get('assigned_to').value;

    if (
      (startsAt != null && startsAt !== '') ||
      (endsAt != null && endsAt !== '') ||
      (assignedTo != null && assignedTo !== '')
    ) {
      return false;
    }
    return true;
  }

  get inputText(): string {
    const startsAt = this.form.get('starts_at').value;
    const endsAt = this.form.get('ends_at').value;
    const assignedTo = this.form.get('assigned_to').value;

    let ret = '';
    if (startsAt) {
      const date = moment(startsAt).format('DD/MM/YYYY');
      ret += date;

      if (endsAt) {
        const startHour = moment(startsAt).format('HH:mm');
        const endHour = moment(endsAt).format('HH:mm');
        ret += ` das ${startHour} às ${endHour}`;

        if (assignedTo) {
          ret += ` com ${assignedTo}`;
        }
      }
    }
    return ret;
  }

  get value(): AppointmentNested {
    if (this.isEmpty) {
      return null;
    }
    return this.form.value;
  }

  set value(value: AppointmentNested) {
    if (value != null) {
      this.form.patchValue(value);
    } else {
      this.form.reset({ starts_at: null, ends_at: null, assigned_to: null });
    }

    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  writeValue(value): void {
    this.value = value;
  }

  validate(): ValidationErrors {
    if (this.isEmpty) {
      if (this.required) {
        return { invalid: true };
      }
    } else if (this.form.invalid) {
      return { invalid: true };
    }
    return null;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  onInputChange(): void {
    this.onChange(this.value);
  }

  onBlur(): void {
    this.onTouched();
  }
}
